export default {
    path: 'census',
    meta: {
        title: '数据统计'
    },
    component: r => require.ensure([], () => r(require('../../portalPage/census/main')), 'portalCensus'),
    children: [
        {
            path: '',
            redirect: 'order'            
        },
        {
            name: 'portalCensusOrder',
            path: 'order',
            meta:{
                title:'收入金额'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/census/order')), 'portalCensusOrder')
        },
        {
            name: 'portalCensusView',
            path: 'view',
            meta:{
                title:'浏览量'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/census/view')), 'portalCensusView')
        },
        
        
    ]
}