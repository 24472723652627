// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue';
import App from './App';

import router from './router';
import global from './components/global';
import base from './components/base';
import sysRbac from './components/sysRbac';

Vue.prototype.router = router;

// 绑定全局数据及方法
Vue.prototype.$global = global;
Vue.prototype.$base = base;

// 绑定全局组件
Vue.component('sysRbac', sysRbac);

// 引入微信分享签名方法
import wxshare from './util/h5Share';
// 将微信分享方法绑定到全局
Vue.prototype.$wxShare = wxshare;

// 引用API文件
import api from './util/api';
// 将API方法绑定到全局
Vue.prototype.$api = api;

// 引用权限控制方法
import cont from './util/cont';
// 将API方法绑定到全局
Vue.prototype.$cont = cont;

// 引入并绑定全局方法
import utils from './util/utils';
Vue.use(utils);

//全局过滤器文件
import * as custom  from '@/filters/filter.js';
Object.keys(custom).forEach(key => {
  Vue.filter(key, custom[key])
})

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false;
/* eslint-disable no-new */
//runtime
new Vue({
  router,
  render: h => h(App)
}).$mount("#app");

