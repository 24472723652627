export default {
    path: 'account',
    meta: {
        title: '账号管理'
    },
    component: r => require.ensure([], () => r(require('../../portalPage/account/main')), 'portalAccount'),
    children: [
        {
            path: '',
            redirect: 'info'            
        },
        {
            name: 'portalAccountInfo',
            path: 'info',
            meta:{
                title:'账户信息'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/account/info')), 'portalAccountInfo')
        },
        {
            name: 'portalAccountEdit',
            path: 'edit',
            meta:{
                title:'账户信息编辑'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/account/edit')), 'portalAccountEdit')
        },
        {
            name: 'portalAccountInvoice',
            path: 'invoice',
            meta:{
                title:'开发票'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/account/invoice')), 'portalAccountInvoice')
        },
        {
            name: 'portalAccountInvite',
            path: 'invite',
            meta:{
                title:'嘉宾邀约'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/account/invite')), 'portalAccountInvite')
        },
        {
            name: 'portalAccountService',
            path: 'service',
            meta:{
                title:'会务服务'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/account/service')), 'portalAccountService')
        },
        {
            name: 'portalAccountNews',
            path: 'news',
            meta:{
                title:'消息中心'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/account/news')), 'portalAccountNews')
        },
        {
            name: 'portalAccountPurse',
            path: 'purse',
            meta:{
                title:'账户'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/account/purse')), 'portalAccountPurse')
        }
        
        
    ]
}