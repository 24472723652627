export default {
    path: 'resource',
    meta: {
        title: '资源管理'
    },
    component: r => require.ensure([], () => r(require('../../portalPage/resource/main')), 'portalResource'),
    children: [
        {
            path: '',
            redirect: 'list'            
        },
        {
            name: 'portalResourceList',
            path: 'list',
            meta:{
                title:'资源列表'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/resource/list')), 'portalResourceList')
        },
        {
            name: 'portalResourceEdit',
            path: 'edit',
            meta:{
                title:'资源编辑'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/resource/edit')), 'portalResourceEdit')
        },
        {
            name: 'portalResourceInteract',
            path: 'interact',
            meta:{
                title:'互动评论'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/resource/interact')), 'portalResourceInteract')
        },
        
        
    ]
}