import $api from './api.js'; 
// h5微信jssdk
import weixinJsSdk from 'weixin-js-sdk';
export default function(params) {
	/**
	 * 仅限h5分享使用
	 * pageConfigUrl:页面签名url
	 * shareImgUrl:分享图片
	 * shareTitle:分享标题
	 * shareDesc:分享描述
	 * 
	 */
	$api.post({
		url: params.pageConfigUrl,
		data:{url: encodeURIComponent(location.href)},
		success:res=>{
			console.log('页面签名信息：',res)
			weixinJsSdk.config({
				debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
				appId: res.appId, // 必填，公众号的唯一标识
				timestamp: res.signMap.timestamp, // 必填，生成签名的时间戳
				nonceStr: res.signMap.nonceStr, // 必填，生成签名的随机串
				signature: res.signMap.signature, // 必填，签名
				jsApiList: ['onMenuShareTimeline','onMenuShareAppMessage','onMenuShareQQ','onMenuShareQZone'] // 必填，需要使用的JS接口列表
			})
			onH5Share();
		}
	})
	function onH5Share(){
		weixinJsSdk.ready(()=>{
			//分享给朋友
			weixinJsSdk.onMenuShareAppMessage({
				title: params.shareTitle || '陆想汇精彩内容分享',
				desc: params.shareDesc || '...',
				link: location.href,
				imgUrl: params.shareImgUrl,
				success: function () { 
					
				}
			});
			//分享到朋友圈
			weixinJsSdk.onMenuShareTimeline({
				title: params.shareTitle || '陆想汇精彩内容分享',
				desc: params.shareDesc || '...',
				link: location.href,
				imgUrl: params.shareImgUrl,
				success: function () { 
					
				}
			});
			//分享到QQ
			weixinJsSdk.onMenuShareQQ({
				title: params.shareTitle || '陆想汇精彩内容分享',
				desc: params.shareDesc || '...',
				link: location.href,
				imgUrl: params.shareImgUrl,
				success: function () {
				}
			});
			//分享到QQ空间
			weixinJsSdk.onMenuShareQZone({
				title: params.shareTitle || '陆想汇精彩内容分享',
				desc: params.shareDesc || '...',
				link: location.href,
				imgUrl: params.shareImgUrl,
				success: function () { 
					
				}
			});
		});
	}


}
