/* 设置金额格式  1,234.56 */
let _dateFormat = (value, column) =>  {
    if(value){
      return new Date(value).format('yyyy-MM-dd hh:mm:ss');
    }else{
      return "";
    }
}

//定义下全局过滤器  往 {}里面加 逗号隔开
export  {_dateFormat}