export default {
    path: 'user',
    meta: {
        title: '用户管理'
    },
    component: r => require.ensure([], () => r(require('../../portalPage/user/main')), 'portalUser'),
    children: [
        {
            path: '',
            redirect: 'list'            
        },
        {
            name: 'portalUserList',
            path: 'list',
            meta:{
                title:'用户列表'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/user/list')), 'portalUserList')
        },
        {
            name: 'portalUserGroup',
            path: 'group',
            meta:{
                title:'用户分组'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/user/group')), 'portalUserGroup')
        },
        {
            name: 'portalUserDetail',
            path: 'detail/:id',
            meta:{
                title:'用户详情'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/user/detail')), 'portalUserDetail')
        }
        
        
    ]
}