<template>
    <div class="login_page">
        <div class="login_page_left">
            <div class="page_left_center">
                <img class="lxh_logo" src="/static/images/portal/lxh_logo_lang.png" alt="陆想汇">
                <img class="lxh_logo_mark" src="/static/images/portal/lxh_mark.png" alt="汇聚思想，共享成长">
            </div>
        </div>
        <div class="login_page_right">
            <div class="login_module" v-show="current==='login'">
                <div class="login_title">陆想汇商家后台</div>
                <div class="login_tab">
                    <div @click="loginType = 1" class="login_tab_item" :class="loginType == 1?'login_tab_item_active':''">密码登录</div>
                    <div @click="loginType = 2" class="login_tab_item" :class="loginType == 2?'login_tab_item_active':''">短信登录</div>
                </div>
                <el-form @submit.native.prevent 
                    v-if="loginType == 1"
                    :model="loginForm" 
                    :rules="loginRules" 
                    ref="loginForm"
                    label-position="left"
                    class="login_from"
                    >
                    <el-form-item prop="loginName">
                        <el-input class="login_input" v-model="loginForm.loginName" placeholder="账号/手机号"></el-input>
                    </el-form-item>
                     <el-form-item prop="loginPwd">
                        <el-input class="login_input" v-model="loginForm.loginPwd" placeholder="密码" show-password></el-input>                        
                    </el-form-item>
                </el-form>
                <el-form @submit.native.prevent 
                    v-if="loginType == 2"
                    :model="loginForm2" 
                    :rules="loginRules2" 
                    ref="loginForm2"
                    label-position="left"
                    class="login_from"
                    >
                    <el-form-item prop="phone">
                        <div class="login_input">
                            <el-input class="login_input_short" v-model="loginForm2.phone" placeholder="手机号"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item prop="mobileVerifyCode">
                      <div class="login_input">
                        <el-input class="login_input" v-model="loginForm2.mobileVerifyCode" placeholder="验证码"></el-input>
                        <el-button  @click="bindGetLoginCode" class="psw_code_btn"><span v-if="loginTime">{{loginTime}}s</span><span v-else>获取验证码</span></el-button>
                      </div>
                    </el-form-item>
                </el-form>
                <el-button :loading="loginFlag" @click="loginHandler" class="login_btn">登录</el-button>
                <div class="login_tips">
                    <div class="tips_left" @click="current = 'password'">忘记密码</div>
                    <div class="tips_right"><span>还没账号？</span><span @click="bind2Reg" class="cr_blue">去注册></span></div>
                </div>
            </div>
            <div class="login_module" v-show="current==='password'">
                <el-form @submit.native.prevent 
                    :model="passwordForm" 
                    :rules="passwordRules" 
                    ref="passwordForm"
                    label-position="left"
                    class="login_from"
                    >
                    <div class="login_title">重置密码</div>
                    <el-form-item prop="userMobile">
                        <div class="login_input">
                            <el-input class="login_input_short" v-model="passwordForm.userMobile" placeholder="手机号"></el-input>
                        </div>
                    </el-form-item>
                    <el-form-item prop="valiCode">
                      <div class="login_input">
                        <el-input class="login_input" v-model="passwordForm.valiCode" placeholder="验证码"></el-input>
                        <el-button @click="bindGetCode" class="psw_code_btn"><span v-if="passworrdTime">{{passworrdTime}}s</span><span v-else>获取验证码</span></el-button>
                      </div>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input class="login_input" v-model="passwordForm.password" placeholder="新密码" show-password></el-input>                        
                    </el-form-item>
                     <el-form-item prop="password2">
                        <el-input class="login_input" v-model="passwordForm.password2" placeholder="确认密码" show-password></el-input>                        
                    </el-form-item>
                </el-form>
                <el-button :loading="resetFlag" @click="resetHandler" class="login_btn reset_btn">立即重置</el-button>
                <div class="login_tips">
                    <div class="tips_left cr_blue" @click="current = 'login'">登录</div>
                    <div class="tips_right"><span>还没账号？</span><span @click="bind2Reg" class="cr_blue">去注册></span></div>
                </div>
            </div>
            <div style="position: absolute;width: 100%;bottom: 0;text-align: center;color: #666666;font-size: 14px;overflow: hidden;line-height: 20px;padding: 10px 0;"> 
                <a href="http://beian.miit.gov.cn/" target="_blank"><div style="text-decoration: none;" class="footer_bot">© Copyright&nbsp;&nbsp;2019&nbsp;&nbsp;陆想汇&nbsp;&nbsp;-2&nbsp;&nbsp;&nbsp;&nbsp;All Rights Reserved 上海特陆文化传播有限公司</div></a>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    created(){
        console.log(process.env);
        this.pageConfig();
    },
    data() {
        return {
            loginFlag: false,
            resetFlag: false,
            goUrl: this.$route.query.goUrl,
            current: location.href.indexOf('login')>0?'login':'password',
            loginTime: 0,
            loginInterval: 0,
            passworrdTime: 0,
            passwordInterval: 0,
            loginType: 1,//1:密码登录；2：短信登录
            loginForm: {
                loginName: '',
                loginPwd: '',
            },
            loginForm2: {
                phone: '',
                mobileVerifyCode: '',
            },
            passwordForm:{
                userMobile: '',
                valiCode: '',
                password: '',
                password2: '',
            },
            loginRules: {
                loginName: [
                    { required: true, message: '请输入用户名', trigger: 'blur' }
                ],
                loginPwd: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min:6,max:20, message: '密码6-20位', trigger: 'blur' }
                ]

            },
            loginRules2: {
                phone: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { validator: this.validateUserMobile, trigger: 'blur'}
                ],
                mobileVerifyCode: [
                    { required: true, message: '请输入验证码', trigger: 'blur' }
                ],
            },
            passwordRules:{
                userMobile: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { validator: this.validateUserMobile, trigger: 'blur'}
                ],
                valiCode: [
                    { required: true, message: '请输入验证码', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min:6,max:20, message: '密码6-20', trigger: 'blur' }
                ],
                password2: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min:6,max:20, message: '密码6-20位', trigger: 'blur' },
                    { validator:(rule, value, callback)=>{
                        if(this.passwordForm.password2 !== this.passwordForm.password){
                            callback(new Error('两次密码输入不一致'));
                        }else{
                            callback();
                        }
                    }, trigger: 'blur' }
                ]
                
            }
        };
    },
    methods: {
        pageConfig() {
            try { 
                this.$wxShare({
                    pageConfigUrl: 'api-activity/wxShare',
                    shareImgUrl: 'https://lxh-image-001.oss-cn-hangzhou.aliyuncs.com/share_logo.png',
                    shareTitle: '陆想汇商家登陆链接',
                    shareDesc: '一键免费发布活动、直播、文章获取你的专属私域流量。'
                })
            } catch (error) {
                
            }
        },
        validateUserMobile(rule, value, callback) {
            if(!/^1[0-9]{10}$/.test(value)){
                callback(new Error('手机号格式不正确'));
            }else{
                this.$api.post({
                    url:'api-portal/checkPartnersMobile',
                    data:{
                        userMobile: value
                    },
                    success: result => {
                        if(result) {
                            callback();
                        } else {
                            callback(new Error('手机号未注册'));
                        }
                    },
                    fail: result =>{
                        callback(new Error('手机号未注册'));
                    }
                });
            }
        },
        loginHandler() {
            if(this.loginFlag) return;
            if(this.loginType == 1) {//密码登录
                this.$refs.loginForm.validate((valid) => {
                    if (valid) {
                        this.loginFlag = true;
                        this.$api.post({
                            url: 'api-portal/poratlLogin',
                            data: this.loginForm,
                            success: res => {
                                if(res && res.token) {
                                    // this.$message({
                                    //     message: '登录成功',
                                    //     type: 'success'
                                    // });
                                    window.localStorage.setItem('portalToken',res.token)
                                    if(this.goUrl && (this.goUrl.indexOf('register') == -1)) {
                                        location.href = decodeURIComponent(this.goUrl);
                                    } else {
                                        this.$router.push(
                                            {path: '/portal'}  
                                        )
                                    }
                                } else {
                                    this.$message({
                                        message: '登录失败，请稍后重试',
                                        type: 'error'
                                    });
                                }
                            },
                            fail: res => {
                                console.log('登录失败：',res)
                                this.$message({
                                    message: res.data.cnMsg,
                                    type: 'error'
                                });
                            },
                            complete: res =>{
                                this.loginFlag = false;
                            }
                        })
                    }
                })
            } else {//短信登录
                this.$refs.loginForm2.validate((valid) => {
                    if (valid) {
                        this.loginFlag = true;
                        this.$api.post({
                            url: 'api-portal/portalMessageLogin',
                            data: this.loginForm2,
                            success: res => {
                                console.log(res)
                                if(res && res.token) {
                                    // this.$message({
                                    //     message: '登录成功',
                                    //     type: 'success'
                                    // });
                                    window.localStorage.setItem('portalToken',res.token)
                                    if(this.goUrl && (this.goUrl.indexOf('register') == -1)) {
                                        location.href = decodeURIComponent(this.goUrl);
                                    } else {
                                        this.$router.push(
                                            {path: '/portal'}  
                                        )
                                    }
                                } else {
                                    this.$message({
                                        message: '登录失败，请稍后重试',
                                        type: 'error'
                                    });
                                }
                            },
                            fail: res => {
                                console.log('登录失败：',res)
                                this.$message({
                                    message: res.data.cnMsg,
                                    type: 'error'
                                });
                            },
                            complete: res =>{
                                this.loginFlag = false;
                            }
                        })
                    }
                })
            }
            
        },
        bindGetLoginCode() {
            if(this.loginTime){
                return;
            }
            this.$refs.loginForm2.validateField('phone', valid => {
                if(!valid){
                    const loading = this.$loading({
                        target: '.psw_code_btn',
                        lock: true,
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    this.$api.post({
                        url: 'api-user/sendTencentMessage',
                        data: {
                            function: 23,
                            userMobile: this.loginForm2.phone  
                        },
                        success: res => {
                            clearInterval(this.loginInterval);
                                this.loginTime = 60;
                                this.loginInterval = window.setInterval(()=>{
                                this.loginTime--;
                                if(this.loginTime===0){
                                    clearInterval(this.loginInterval);
                                }
                            },1000);
                            loading.close();
                        },
                        fail: res => {
                            this.$message({
                                message: res.data.cnMsg,
                                type: 'error'
                            });
                        }
                    })

                }
            })
        },
        bindGetCode() {
            if(this.passworrdTime){
                return;
            }
            this.$refs.passwordForm.validateField('userMobile', valid => {
                if(!valid){
                    const loading = this.$loading({
                        target: '.psw_code_btn',
                        lock: true,
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    this.$api.post({
                        url: 'api-user/sendTencentMessage',
                        data: {
                            function: 2,
                            userMobile: this.passwordForm.userMobile  
                        },
                        success: res => {
                            clearInterval(this.passwordInterval);
                                this.passworrdTime = 60;
                                this.passwordInterval = window.setInterval(()=>{
                                this.passworrdTime--;
                                if(this.passworrdTime===0){
                                    clearInterval(this.passwordInterval);
                                }
                            },1000);
                            loading.close();
                        },
                        fail: res => {
                            this.$message({
                                message: res.data.cnMsg,
                                type: 'error'
                            });
                        }
                    })

                }
            })
        },
        resetHandler() {
            if(this.resetFlag) return;
            this.$refs.passwordForm.validate((valid) => {
                if (valid) {
                    this.resetFlag = true;
                    this.$api.post({
                        url: 'api-portal/resetAccountPassword',
                        data: {
                            userMobile:  this.passwordForm.userMobile,
                            valiCode: this.passwordForm.valiCode,
                            password: this.passwordForm.password
                        },
                        success: res => {
                            console.log('重置成功：',res)
                            this.$message.success('重置密码成功，请登录');
                            this.current = 'login';
                        },
                        fail: res => {
                            this.$message({
                                message: res.data.cnMsg,
                                type: 'error'
                            });
                        },
                        complete: res =>{
                            this.resetFlag = false;
                        }
                    }) 
                }
            })
        },
        bind2Reg() {
            this.$router.push(
                {path: '/portal/register'}  
            )
        }
    }
}
</script>
<style>
    html{
        height: 100%;
        overflow-y: auto!important;
    }
    body{
        height: 100%;
        overflow-y: auto!important;
        margin:0;
    }
</style>
<style lang="scss" scope>
    @import '@/style/portal/login.scss';
</style>
