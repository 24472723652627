<template>
    <!-- 购买单选功能弹窗组件 -->
    <div>
        <el-dialog
            title="购买单项功能"
            :visible.sync="sysRbacDialog"
            width="600px"
            >
            <div v-if="funcData" class="order-dialog">
                <div class="order_item">
                    <div class="order_title">功能名称：</div>
                    <div class="order_content">{{funcData.name}}</div>
                </div>
                <div class="order_item">
                    <div class="order_title">单位：</div>
                    <div class="order_content">{{ funcData.frequency?(funcData.frequency + '次'):(funcData.timeType == 1?'季':(funcData.timeType == 2?'年':'月')) }}</div>
                </div>
                <div class="order_item">
                    <div class="order_title">购买数量：</div>
                    <div class="order_content">
                        <el-input-number @change="checkTotalPrice" style="width: 160px;" size="medium" v-model="totalNum" :min="1" :max="999999"></el-input-number>
                    </div>
                </div>
                <div class="order_item">
                    <div class="order_title">金额：</div>
                    <div class="order_content"><span class="order_price">￥{{ totalPrice }}</span></div>
                </div>
                <div class="order_item">
                    <div class="order_title">支付方式：</div>
                    <div class="order_content">
                        <div @click="selectPayType = 2" class="pay_select" >
                            <el-checkbox :value="selectPayType" :true-label='2' ></el-checkbox>
                            <img class="pay_img" src="/static/images/portal/pay_zfb.png" />
                            <span>支付宝</span>
                        </div>
                        <div @click="selectPayType = 0" class="pay_select" >
                            <el-checkbox :value="selectPayType" :true-label='0' ></el-checkbox>
                            <img class="pay_img" src="/static/images/portal/pay_wx.png" />
                            <span class="color_wx">微信支付</span>
                        </div>
                        <div @click="selectPayType = 1" class="pay_select" >
                            <el-checkbox :value="selectPayType" :true-label='1' ></el-checkbox>
                            <!-- <img class="pay_img" src="/static/images/portal/pay_wx.png" /> -->
                            <span class="color_purse">账户余额</span>
                        </div>
                    </div>
                </div>
                <el-button class="order-dialog_btn" @click="bindCreatOrder" :loading="payLoading" type="primary">立即购买</el-button>
            </div>
            <div v-else v-loading="loading" class="order-dialog" style="line-height: 100px;text-algin: center;font-size: 14px;color:#999999;">加载中...</div>
                
        </el-dialog>
        <!-- 支付弹窗 -->
        <el-dialog
            title="微信支付"
            :visible.sync="showWxPayCode"
            :close-on-click-modal="false"
            width="600px">
            <div v-if="funcData" class="order-dialog">
                <div class="wx_pay_shadow_tips"><img class="wx_img" src="/static/images/portal/pay_wx.png" />微信扫一扫支付（元）</div>
                <div class="wx_pay_shadow_price">{{ totalPrice }}</div>
                <img class="wx_pay_code" :src="wxPayCode" alt="微信支付">
            </div>
        </el-dialog>
        <!-- 支付弹窗 end -->
    </div>
</template>

<style lang="scss" scope>

</style>
<script>
export default {
    name: 'sysRbac',
    data () {
        return {
            code: '',
            loading: false,
            sysRbacDialog: false,//购买弹窗
            payLoading: false,
            funcData: null,//功能详情
            selectPayType: 1,//支付方式  //支付类型 0 微信支付 1 账户支付  2 支付宝支付
            showWxPayCode: false,//微信扫码付款
            totalNum: 1,//购买数量
            totalPrice: 0,//总价
            wxPayCode: this.$global.pagebasePath + 'api-activity/createSystemOrderQrcode?orderId=',
            payTimer: 0,
        }
    },
    beforeDestroy () { 
        if(this.payTimer) {
            clearInterval(this.payTimer);
            this.payTimer = 0;
        }   
    },
    methods:{
        switchRbacDialog(code,type) {//type:1:关闭
            if(type) {
                this.sysRbacDialog = false;
            } else {
                this.code = code;
                this.sysRbacDialog = true;
                this.loading = true;
                this.$api.post({
                    url: 'api-portal/getFunctionDetail',
                    data: {code: code},
                    success: res => {
                        this.loading = false;
                        if(res && res.paySystemFunction) {
                            let fdata = res.paySystemFunction;
                            if((new Date() < new Date(fdata.discountTime)) && (fdata.discountPrice || fdata.discountPrice === 0)) {
                                fdata.isDiscount = 1;//优惠时间未过期
                            } else {
                                fdata.isDiscount = 0;//优惠时间已过期
                            }
                            this.funcData = fdata;
                            this.totalNum = 1;
                            this.checkTotalPrice();
                        } else {
                            this.sysRbacDialog = false;
                            this.$confirm('需要购买套餐才能使用该功能，是否立即去购买？', '提示', {
                            type: 'warning'
                            }).then(() => {
                                this.$router.push({
                                    path: '/portal/service/meal_new'
                                })
                            })    
                        }
                    },
                    fail: res => {
                        this.loading = false;
                        this.sysRbacDialog = false;
                        this.$message.error(res.data.cnMsg)
                    }
                })
            }
        },
        checkTotalPrice () {
            if(this.funcData.isDiscount) {
                this.totalPrice = (this.funcData.discountPrice * this.totalNum).toFixed(2);
            } else {
                this.totalPrice = (this.funcData.price * this.totalNum).toFixed(2);
            }
        },
        checkOrderType(id) {
            this.$api.post({
                url: 'api-portal/getSystemOrderStatus',
                data: {orderId: id},
                success: res => {
                    if(res == 1){
                        this.$emit('bindComplete',{payType: this.selectPayType, orderType: 1, code: this.code});
                        // this.$message.success('支付成功');
                        clearInterval(this.payTimer);
                        this.payTimer = 0;
                        this.showWxPayCode = false;
                    }
                }
            })
        },
        bindCreatOrder() {//结算
            if(this.payLoading) return false;
            this.payLoading = true;
            let sData = {payType: this.selectPayType,functionId: this.funcData.id, quantity: this.totalNum};
            this.$api.post({
                url: 'api-portal/createSystemOrder',
                data: sData,
                success: res => {
                    console.log('---生成订单成功---',res);
                    this.payLoading = false;
                    if(this.selectPayType == 2) {//支付宝支付
                        this.sysRbacDialog = false;
                        window.open(this.$global.pagebasePath + 'api-activity/goAliSystemOrder?orderId=' + res.orderId + '&portalToken=' + localStorage.getItem('portalToken') + '&goUrl=' + encodeURIComponent(location.href))
                    } else if(this.selectPayType == 0) {//微信支付
                        this.wxPayCode = this.wxPayCode.split('?')[0] + '?orderId=' + res.orderId + '&portalToken=' + localStorage.getItem('portalToken');
                        this.sysRbacDialog = false;
                        this.showWxPayCode = true;
                        this.$nextTick(() =>{
                            this.payTimer = setInterval(() => {
                                this.checkOrderType(res.orderId);
                            }, 1000);            
                        })
                    } else {
                        if(res.orderStatus == 1) {
                            this.sysRbacDialog = false;
                            this.$message.success('支付成功');
                            this.$emit('bindComplete',{payType: this.selectPayType, orderType: 1, code: this.code});
                        }
                    }
                },
                fail: res => {
                    this.payLoading = false;
                    this.$message.error(res.data.cnMsg)
                }
            })
        },
    }
}
</script>
