exports.install = function (Vue) {

    //格式化table字段 如果超过字符长度超过7位则用...代替
    Vue.prototype.$tabFormat = function (row, column, cellValue){
      let str = '...';
      let value = '';
      if(cellValue.length > 7){
        value = cellValue.substr(0,7)+str;
        return value;
      }else {
        return cellValue;
      }
    };
  
    /**
     * 保留2位小数不四舍五入
     * @param value
     * @returns {*}
     */
    Vue.prototype.$formatnumst = function (value){
  
      var f = parseFloat(value);
      if (isNaN(f)) {
        return false;
      }
      var f2 = Math.round(value*100)/100;
      var s = f2.toString();
      var rs = s.indexOf('.');
      if (rs < 0) {
        rs = s.length;
        s += '.';
      }
      while (s.length <= rs + 2) {
        s += '0';
      }
      return s;
  
  
  
    };
    /**
     * 保留4位小数
     * @param x不四舍五入
     * @returns {*}
     */
    Vue.prototype.$formatmumbf = function (x){
      var f = parseFloat(x);
      if (isNaN(f)) {
        return false;
      }
      var f2 = Math.round(x*10000)/10000;
      var s = f2.toString();
      var rs = s.indexOf('.');
      if (rs < 0) {
        rs = s.length;
        s += '.';
      }
      while (s.length <= rs + 4) {
        s += '0';
      }
      return s;
  
    };
    /*金额格式化*/
    Vue.prototype.$price_format= function (number, decimals, dec_point, thousands_sep) {
      number = (number + '').replace(/[^0-9+-Ee.]/g, '');
      var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 2 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function(n, prec) {
          var k = Math.pow(10, prec);
          return '' + Math.ceil(n * k) / k;
        };
  
      s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
      var re = /(-?\d+)(\d{3})/;
      while(re.test(s[0])) {
        s[0] = s[0].replace(re, "$1" + sep + "$2");
      }
  
      if((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
      }
      return s.join(dec);
    }
  
    /**
     * 校验手机号
     * @param num
     * @returns {*|boolean}
     */
    Vue.prototype.$isPhone= function (num) {
      return /^1[2,3,4,5,6,7,8,9]\d{9}$/.test(num)
  
    }

    /**
     * 校验邮箱格式
     * @param num
     * @returns {*|boolean}
     */
    Vue.prototype.$isEmail = function(num) {
      return /^\w+((-\w+)|(\.\w+))*\\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/.test(num);
    }


    /**
     * 校验中英文姓名
     */
    Vue.prototype.$isName= function (name) {
      return /[a-zA-Z\u4E00-\u9FA5]+$/.test(name)
  
    }
  
    /**
     * 校验输入金额
     * @param n
     * @returns {*|boolean}
     */
    Vue.prototype.$isMoney = function (n) {
      return /^\d+$/.test(n)
    }
  
    /**
     * 去空格
     * @param str
     * @returns {*}
     */
    Vue.prototype.$trim = function (str) {
      return str.replace(/(^\s*)|(\s*$)/g, '')
    }
    /**
     * 返回精确的n位小数数值
     * @param num:number
     * @param dig:number
     */
  
    Vue.prototype.$digitToFixed = function (num, digit = 2) {
      // NaN，undefined，空值
      if (typeof (num) == 'undefined') {
        return '0.00'
      }else {
        return parseFloat(num).toFixed(digit)
      }
    }
    /**
     * 返回账户金额，每隔三位数字添加一个逗号
     * @param str:string
     */
  
    Vue.prototype.$threeNumberAPointer = function (str) {
      return parseFloat(str).toFixed(2).replace(/\d(?=(?:\d{3})+\b)/g, `$&,`)
    }
  
    /**
     * 判断当前选择时间是否小于是当前实际时间
     */
    Vue.prototype.$contrastTime = function(start,end) {
      // console.log(start,end)
      if(end) {
        return new Date(end) > new Date(start);//当前时间大于传入时间？
      } else {
        return new Date() > new Date(start);//当前时间大于传入时间？
      }
    }
  
  };
  