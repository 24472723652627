<template>
    <router-view></router-view>
</template>

<script>
export default {
  name: 'app',
  components: {},
  data(){
    return {}
  }
}
</script>
