<template>
    <el-header>
        <div v-if="userData" class="portal_header">
            <el-popover
                placement="bottom"
                width="400"
                trigger="click"
                v-model="isShowNews">
                <div class="portal_header_news">
                    <template v-if="newsList && newsList.length">
                    <div v-for="(item,index) in newsList" :key="index" @click.stop="bind2Page('/portal/account/news?id=' + item.id)"  class="portal_header_news_item overflow_multi">{{item.title}}</div>
                    </template>
                    <div v-else class="empty_news">- 暂时没有新消息 -</div>
                    <div @click.stop="bind2Page('/portal/account/news')" class="portal_header_news_btn">查看全部消息 >> </div>
                </div>
                <div class="portal_header_item" slot="reference">
                    <div class="news_module">
                        <img src="/static/images/portal/header/notice.png" alt="通知">
                        <span v-if="newsNum" class="red_bot"><span v-if="newsNum < 10">{{newsNum}}</span><span v-else>9+</span></span>
                    </div>
                </div>
            </el-popover>
            <div @click="bind2PreModule" class="portal_header_item">切回旧版</div>
            <!-- <div class="portal_header_item" @click="bind2Page('/portal/account/info')"><div class="account_logo"><img :src="userData.logoUrl" :alt="userData.sellerName"></div><span>{{userData.sellerName}}</span></div> -->
            <div @click="bind2Help" class="portal_header_item">使用手册</div>
            <div @click="adviseDialog = true" class="portal_header_item">意见反馈</div>
            <el-popover
                placement="bottom"
                width="300"
                trigger="click">
                <div class="portal_header_contact">
                    <img class="portal_header_contact_img" style="transform: scale(1.15)" src="/static/images/portal/wx_contact_code.png" alt="联系我们">
                    <div class="portal_header_contact_tips">↑ 扫码添加客服微信</div>
                    <div class="portal_header_contact_bot">客服电话：17301836228</div>
                </div>
                <div slot="reference" class="portal_header_item">联系我们</div>
            </el-popover>

            <el-dropdown class="portal_header_item" placement="bottom" @command="headerMenuClick" :show-timeout="100">
                <span class="el-dropdown-link">
                    <div><div class="account_logo"><img :src="userData.logoUrl" :alt="userData.sellerName"></div><span>{{userData.sellerName}}<i class="el-icon-arrow-down el-icon--right"></i></span></div>
                    <!-- 账号管理<i class="el-icon-arrow-down el-icon--right"></i> -->
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="1"><img class="account_drow_img" src="/static/images/portal/header/account.png" /><span class="account_drow_text">账号信息</span></el-dropdown-item>
                    <el-dropdown-item command="5"><img class="account_drow_img" src="/static/images/portal/header/purse.png" /><span class="account_drow_text">账户</span></el-dropdown-item>
                    <el-dropdown-item command="4"><img class="account_drow_img" src="/static/images/portal/header/invoic.png" /><span class="account_drow_text">申请开票</span></el-dropdown-item>
                    <el-dropdown-item command="2"><img class="account_drow_img" src="/static/images/portal/header/rePassword.png" /><span class="account_drow_text">修改密码</span></el-dropdown-item>
                    <el-dropdown-item command="3" class="account_drow_bot"><img class="account_drow_img" src="/static/images/portal/header/quit.png" /><span class="account_drow_text">退出登录</span></el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        <!-- 问卷调查 -->
        <!-- <div v-show="isOpenPortalSurvey" @click.stop="switchSurveyDiolog" id="portalSurvey">问卷</div> -->
        <img v-show="isOpenPortalSurvey" @click.stop="switchSurveyDiolog" id="portalSurvey" src="/static/images/portal/survey.png" alt="问卷调查">
        <!-- 问卷弹窗 -->
        <el-dialog
            title="新版本使用反馈"
            :visible.sync="surveyDiolog"
            :close-on-click-modal="false"
            width="460px"
            style="margin-top: 15vh;">
            <div>
                <div class="survey-diolog_content">为了给您提供更好的服务，希望您能抽出2分钟时间，将您对新版的使用感受和建议告诉我们。<br/><br/>我们非常重视每位用户的宝贵意见，期待您的参与！ </div>
                <div style="text-align: center;">
                    <el-button type="primary" @click="bind2Survey">去填写</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 问卷弹窗 end -->
        <!-- 意见弹窗 -->
        <el-dialog
            title="请留下您的宝贵意见或建议！"
            :visible.sync="adviseDialog"
            :close-on-click-modal="false"
            width="460px">
            <div>
                <el-input style="margin-bottom: 20px;" type="textarea" :rows="8" v-model="adviseForm.content" placeholder="写下您想说的话"></el-input>
                <el-input style="margin-bottom: 20px;" v-model="adviseForm.contactInformation" placeholder="输入您的邮箱/手机号"></el-input>
                <div style="text-align: right;">
                    <el-button @click="adviseDialog = false">取 消</el-button>
                    <el-button type="primary" :loading="adviseFlag" @click="bindSendAdvise">发送</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 意见弹窗 end -->
    </el-header>
</template>
<style scoped lang="scss">
    @import '../style/portal/header.scss';
    .survey-diolog_content{font-size: 16px;line-height: 26px;color: #666666;margin-bottom: 20px;}
    // #portalSurvey{-moz-user-select:none; /*火狐*/-webkit-user-select:none; /*webkit浏览器*/-ms-user-select:none; /*IE10*/-khtml-user-select:none; /*早期浏览器*/user-select:none;position: fixed;height: 70px;width: 70px;right: 10px;bottom: 300px;text-align: center;line-height: 70px;border-radius: 50%;background: rgb(34,151,240);z-index: 9;font-size: 20px;color: #fff;box-shadow: 0 4px 10px rgba(160,160,160,.8);cursor: pointer;}
    #portalSurvey{position: fixed;height: 92px;width: 68px;right: 0;bottom: 20vh;z-index: 9;cursor: pointer;}
</style>
<script>

export default {
    props:{
        type: {
            type: String,
            default: ''
        }
    },
    name: 'portalHeader',
    created() {
           this.getUser();
           this.getNews();
           if(this.isOpenPortalSurvey) {
               this.$nextTick(() => {
                    this.portalSurveyInit();
                    this.surveyBtnInit();
               })
           }
    },
    data () {
        return {
            userData: null,
            adviseFlag: false,
            isShowNews: false,
            newsList: [],
            newsNum: 0,
            adviseDialog: false,//意见反馈弹窗
            isOpenPortalSurvey: false,//问卷调查开关
            surveyDiolog: false,//问卷调查
            surveyUrl: 'https://jinshuju.net/f/lbSPlW',//问卷调查地址
            helpPDF: 'https://lxh-uni-file.oss-cn-shanghai.aliyuncs.com/portal/%E5%95%86%E5%AE%B6%E5%90%8E%E5%8F%B0%E4%BD%BF%E7%94%A8%E6%89%8B%E5%86%8C.pdf',
            adviseForm: {
                contactInformation: '',
                content: ''
            },
        }
    },
    methods:{
        bind2PreModule() {
            location.href = 'https://www.luxianghui.com/portal/'
        },
        bind2Survey() {
            this.surveyDiolog = false;
            window.localStorage.setItem('portalSurveyStorage',JSON.stringify({hadSurvey: 5,surverDate: new Date().getTime()}))
            window.open(this.surveyUrl);
        },
        switchSurveyDiolog(type) {//1:关闭
            if(type == 1) {
                this.surveyDiolog = false;
            } else {
                this.surveyDiolog = true;
            }

        },
        portalSurveyInit() {//问卷调查初始化
            let portalSurveyStorage = window.localStorage.getItem('portalSurveyStorage');
            if(portalSurveyStorage) {
                portalSurveyStorage = JSON.parse(portalSurveyStorage);
                let surveyCount = portalSurveyStorage.hadSurvey || 0;
                if((surveyCount < 5) && (new Date().getTime() - portalSurveyStorage.surverDate > 86400000)){//超过一天并且未弹窗满5次
                    surveyCount += 1;
                    window.localStorage.setItem('portalSurveyStorage',JSON.stringify({hadSurvey: surveyCount,surverDate: new Date().getTime()}))
                    setTimeout(() => {
                        this.surveyDiolog = true;
                    },3000)
                }
            } else {
                window.localStorage.setItem('portalSurveyStorage',JSON.stringify({hadSurvey: 0,surverDate: new Date().getTime()}))
            }
        },
        surveyBtnInit() {//问卷按钮拖动效果初始化

        },
        bind2Help() {//查看使用手册
            window.open(this.helpPDF)
        },
        logout(){// 退出登录
            this.$confirm('确认要退出登录吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$api.post({
                    url: 'api-portal/portalSignOut',
                    success: ()=>{
                        localStorage.removeItem('portalToken');
                        this.bind2Page('/portal/login');
                    }
                });
            }).catch(() => {
            });
            
        },
        getUser(){//获取用户信息
            this.$api.post({
                url: 'api-portal/getPartner',
                success: (res)=>{
                    // console.log(res)
                    if(res) {
                        let userdata = res;
                        if(userdata.logoUrl && userdata.logoUrl.indexOf("-cn-hangzhou.aliyuncs.com") != -1){
                            userdata.logoUrl = userdata.logoUrl + '?x-oss-process=image/resize,w_200'
                        }
                        this.userData = userdata;
                    }
                }
            });
        },
        getNews() {//查询未读消息
            this.$api.post({
                url: 'api-portal/listPartnersMessage',
                data: {status: 1},
                success: res => {
                    // console.log(res);
                    if(res && res.list && res.list.length) {
                        this.newsNum = res.count;
                        let list = res.list;
                        if(list.length > 3) {
                            list = list.splice(0,3)
                        }
                        this.newsList = list;
                    }
                }
            })
        },
        headerMenuClick(e) {
            if(e) {
                if(e == 1){
                    this.bind2Page('/portal/account/info');
                } else if (e == 2) {
                    this.bind2Page('/portal/password');
                } else if (e == 3) {
                    this.logout()
                } else if(e == 4) {
                    this.bind2Page('/portal/account/invoice');
                } else if(e == 5) {
                    this.bind2Page('/portal/account/purse');
                }
            }
        },
        bind2Page(url) {
            console.log(url)
            this.isShowNews = false;
            this.$router.push(url)
        },
        bindSendAdvise() {//发送建议
            if(this.adviseFlag) return false;
            this.adviseFlag = true;
            if(!this.adviseForm.content) {
                this.$message.error('请正确输入意见或建议！');
                this.adviseFlag = false;
                return false;
            }
            if((this.adviseForm.contactInformation) && !(this.$isPhone(this.adviseForm.contactInformation)) && !(this.$isEmail(this.adviseForm.contactInformation))){
                this.$message.error('请输入正确的手机号或者邮箱！');
                this.adviseFlag = false;
                return false;
            }
            this.$api.post({
                url: 'api-portal/userFeedback',
                data: this.adviseForm,
                success: res => {
                    this.$message.success('提交成功！');
                    this.adviseFlag = false;
                    this.adviseDialog = false;
                    this.adviseForm = {
                        contactInformation: '',
                        content: ''
                    }
                },
                fail: res => {
                    this.adviseFlag = false;
                    this.$message.error(res.data.cnMsg)
                }
            })

        },
    }
}
</script>
