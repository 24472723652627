// import {Message} from 'element-ui';
// import router from '../router';
import $api from './api';

/*
  权限控制查看方法
  @code: 权限码
  001: 线下活动；002：线上活动；003：收费活动；004：发布资源；005：发布文章；
  006：店铺模板；007：数据统计；008：名单导出；009：签到系统；0010：拉流配置；
  0011：链接追踪；0012：直播流量；0013：短信通知；0014：广告位banner图；0015：广告位详情页；
  0016：广告位列表页；0017：微信推送；0018：微博推送；0019：短信推送；0020：朋友圈推送；
  0021：服务号推送；0022：线下推流；0023：活动策划；0024：会务服务；0025：技术支持；
  0026：嘉宾经济；0027：专属h5；0028：系列活动专题页；0029：发布课程
*/

function checkCont (code) {
    return new Promise((ree, rjj) => {
        $api.post({
            url: 'api-portal/verifyPermission',
            data: {code: code},
            success: res =>{
                // console.log(res)
                if(res.status == 200) {
                    ree(res)
                } else {
                    let msg = '';
                    if(res.status == 403) {
                        msg = '当前账号暂无该功能权限，是否立即开通？';
                    } else if (res.status == 480002) {
                        msg = '当前账号该系统功能已过期，是否立即续费？';
                    } else if (res.status == 480003) {
                        msg = '该功能使用次数超出限制，是否立即购买？';
                    }  else if (res.status == 480004) {
                        msg = '当前账号该套餐已过期，是否立即续费？';
                    }
                    rjj({status: 0, msg: msg})
                }
            },
            fail: res =>{
                rjj({status: -1,msg: res.data.cnMsg})
            }
        })
    })
}

// 返回在vue模板中的调用接口
export default {
    check: function (code) {
        return checkCont(code)
    },
    
}
