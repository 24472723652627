<template>
    <div class="leftMenu">
        <div @click="goPath('/')" class="lxh_logo">
            <img src="/static/images/portal/lxh_logo.png">
            <span class="lxh_logo_text">陆想汇商家后台</span>
        </div>
        <el-menu 
            :default-active="defaultActive"
            unique-opened
            class="el-menu-vertical-demo" 
            @open="handleOpen"
            @close="handleClose" 
            @select="handleSelect"
            background-color="#122232"
            text-color="#FFFFFF"
            active-text-color="#FFFFFF"
            >
            <el-menu-item index="/portal">
                <img class="menu_img" src="/static/images/portal/menu/home.png">
                <span class="menu_title">首页</span>
            </el-menu-item>
            <el-submenu class="product" index="/portal/activity">
                <template slot="title">
                <img class="menu_img" src="/static/images/portal/menu/activity.png">
                <span class="menu_title">活动管理</span>
                </template>
                <el-menu-item index="/portal/activity/list">活动列表</el-menu-item>
                <!-- <el-menu-item index="/portal/activity/order">名单</el-menu-item> -->
                <el-menu-item index="/portal/activity/interact">互动</el-menu-item>
            </el-submenu>
            <el-submenu class="product" index="/portal/live">
                <template slot="title">
                <img class="menu_img" src="/static/images/portal/menu/live.png">
                <span class="menu_title">直播管理</span>
                </template>
                <el-menu-item index="/portal/live/list">直播列表</el-menu-item>
                 <el-menu-item index="/portal/activity/roomTable">直播互动</el-menu-item>
                <el-menu-item index="/portal/live/flow">流量管理</el-menu-item>
            </el-submenu>
            <el-submenu class="product" index="/portal/course">
                <template slot="title">
                <img class="menu_img" src="/static/images/portal/menu/course.png">
                <span class="menu_title">课程管理</span>
                </template>
                <el-menu-item index="/portal/course/list">课程列表</el-menu-item>
            </el-submenu>
            <el-submenu class="product" index="/portal/article">
                <template slot="title">
                <img class="menu_img" src="/static/images/portal/menu/article.png">
                <span class="menu_title">文章管理</span>
                </template>
                <el-menu-item index="/portal/article/list">文章列表</el-menu-item>
            </el-submenu>
            
            <el-submenu class="product" index="/portal/resource">
                <template slot="title">
                <img class="menu_img" src="/static/images/portal/menu/resource.png">
                <span class="menu_title">资源管理</span>
                </template>
                <el-menu-item index="/portal/resource/list">资源列表</el-menu-item>
                <!-- <el-menu-item index="/portal/resource/interact">互动评论</el-menu-item> -->
            </el-submenu>
            <el-submenu class="product" index="/portal/user">
                <template slot="title">
                <img class="menu_img" src="/static/images/portal/menu/user.png">
                <span class="menu_title">用户管理</span>
                </template>
                <el-menu-item index="/portal/user/list">用户列表</el-menu-item>
            </el-submenu>
            <el-submenu class="product" index="/portal/census">
                <template slot="title">
                <img class="menu_img" src="/static/images/portal/menu/tongJi.png">
                <span class="menu_title">数据统计</span>
                </template>
                <el-menu-item index="/portal/census/order">订单数据</el-menu-item>
                <!-- <el-menu-item index="/portal/census/view">访问量</el-menu-item> -->
            </el-submenu>
            <el-submenu class="product" index="/portal/shop">
                <template slot="title">
                <img class="menu_img" src="/static/images/portal/menu/shop.png">
                <span class="menu_title" :class="menuState && menuState.shop?'menu_dot':''">店铺管理</span>
                </template>
                <el-menu-item index="/portal/shop/list"><span code='2'>店铺装修</span></el-menu-item>
                <el-menu-item index="/portal/shop/member"><span code='4'>会员列表</span></el-menu-item>
                <el-menu-item @click="bindCancelDot('shop', 8)" index="/portal/shop/collect"><span code='8' :class="menuState && (menuState.shop & 8)?'menu_sapn_dot':''">合集管理</span></el-menu-item>
            </el-submenu>
            <el-submenu class="product" index="/portal/service">
                <template slot="title">
                <img class="menu_img" src="/static/images/portal/menu/tuiGuang.png">
                <span class="menu_title" :class="menuState && menuState.service?'menu_dot':''">增值服务</span>
                </template>
                <!-- <el-menu-item index="/portal/service/list">增值服务</el-menu-item> -->
                <el-menu-item index="/portal/service/list"><span code='2'>营销推广</span></el-menu-item>
                <!-- <el-menu-item index="/portal/service/sell">分销</el-menu-item> -->
                <el-menu-item index="/portal/service/func"><span code='4'>平台服务</span></el-menu-item>
                <el-menu-item @click="bindCancelDot('service', 8)" index="/portal/service/channel"><span code='8' :class="menuState && (menuState.service & 8)?'menu_sapn_dot':''">渠道分销</span></el-menu-item>
                <!-- <el-menu-item index="/portal/service/meal">付费套餐</el-menu-item> -->
                <!-- <el-menu-item index="/portal/service/meal_new">付费套餐</el-menu-item> -->
                <el-menu-item index="/portal/service/order">服务订单</el-menu-item>
                <el-menu-item index="/portal/service/meal_order">套餐订单</el-menu-item>
            </el-submenu>
            
            <el-menu-item index="/portal/account/purse">
                <img class="menu_img" src="/static/images/portal/menu/purse.png">
                <span class="menu_title">账户</span>
            </el-menu-item>
        </el-menu>
    </div>
</template>

<script>
export default {
    created(){
        this.getUser();
        this.getMenuState();
        if(this.$route.path == '/portal/activity/order') {
            this.defaultActive = '/portal/activity/list';
        } else {
            this.defaultActive = this.$route.path;
        }
    },
    data(){
        return {
            defaultActive: '',
            accountId: '',
            menuState: {//对应菜单标红
                shop: 8,
                service: 8
            },
        };
    },
    methods: {
        handleOpen(key, keyPath) {
            // console.log(key, keyPath);
        },
        handleClose(key, keyPath) {
            // console.log(key, keyPath);
        },
        handleSelect(key, keyPath){ 
            // console.log(key, keyPath);
            this.router.push({
                path: key
            });  
        },
        goPath(path) {
            this.$router.push(path)
        },
        fetchDate(){
            // console.log(this.$route.path)
            setTimeout(()=>{
                if(this.$route.path == '/portal/activity/order') {
                    this.defaultActive = '/portal/activity/list';
                } else {
                    this.defaultActive = this.$route.path;
                }
            },0);
        },
        getUser(){//获取用户信息
            this.$api.post({
                url: 'api-portal/getPartner',
                success: (res)=>{
                    // console.log(res)
                    if(res) {
                        this.accountId = res.accountId || '';
                    }
                }
            });
        },
        getMenuState() {
            try {
                let m = localStorage.getItem('menuState');
                if(m) {
                    m = JSON.parse(m);
                    for(var i in m) {
                        for(var j in this.menuState) {
                            if((i == j) && (m[i] & this.menuState[j]) ) {
                                this.menuState[j] = this.menuState[j] - m[i];
                                this.menuState[j] = this.menuState[j] < 0?0:this.menuState[j];
                            }
                        }
                    }
                }
            } catch (error) {
                
            }
        },
        bindCancelDot(ky, value) {
            if(this.menuState && (this.menuState[ky] & value)) {
                this.menuState[ky] = this.menuState[ky] - value; 
                this.menuState[ky] = this.menuState[ky] < 0?0:this.menuState[ky];
                try {
                    let m = localStorage.getItem('menuState');
                    if(m) {
                        m = JSON.parse(m);
                        if((ky in m)) {
                            if(!(m[ky] & value)) m[ky] += value;
                        } else {
                            m[ky] = value;
                        }
                        localStorage.setItem('menuState', JSON.stringify(m));
                    } else {
                        localStorage.setItem('menuState', JSON.stringify({ky: value}));
                    }
                } catch (error) {}
            }  
            
            
        }
    },
    watch: {
        // 如果路由有变化，高亮制定菜单项
        "$route": "fetchDate",
        "defaultActive": "fetchDate"
    }
}
</script>

