<template>
    <div class="page_footer"> 
        <a href="http://beian.miit.gov.cn/" target="_blank"><div style="text-decoration: none;" class="footer_bot">© Copyright&nbsp;&nbsp;2022-沪ICP备2022030675号-1&nbsp;&nbsp;陆想汇&nbsp;&nbsp;-2&nbsp;&nbsp;&nbsp;&nbsp;All Rights Reserved 上海特陆文化传播有限公司</div></a>
    </div>
</template>

<style lang="scss" scope>
    .page_footer{
        text-align: center;
        color: #666666;
        font-size: 14px;
        overflow: hidden;
        line-height: 20px;
        padding: 10px 0;
    }
</style>
<script>
export default {
    props:{
        type: {
            type: String,
            default: ''
        }
    },
    name: 'portalFooter',
    data () {
        return {
           
        }
    },
    methods:{
      
    }
}
</script>
