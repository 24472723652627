import activity from './activity'
import article from './article'
import course from './course'
import user from './user'
import live from './live'
import service from './service'
import account from './account'
import census from './census'
import shop from './shop'
import resource from './resource'
export default {
    routes:[
        activity,
        article,
        course,
        user,
        live,
        service,
        account,
        census,
        shop,
        resource
    ]
}