<template>
  <div class="lxh-admin-main-page">
    <portalHeader></portalHeader>
    <div class="main-page-view">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"/>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"/>
        <portalFooter />
    </div>
    <portalLeftMenu />
  </div>
</template>

<script>
import portalHeader from '@/components/portalHeader.vue'
import portalLeftMenu from '@/components/portalLeftMenu.vue'
import portalFooter from '@/components/portalFooter.vue'
export default {
  name: 'app',
  components: {portalHeader,portalLeftMenu,portalFooter},
  data(){
      return {
        menuList:[],
      };
  },
  created(){
  //  拿数据
  }
}
</script>

<style lang="scss" scope>
@import './src/style/style.scss';
</style>
