<script type="text/javascript">
let root = process.env.VUE_APP_URL;

const basePath = 'https://portal.luxianghui.com';
const pagebasePath = root.indexOf('https')>=0? root : location.protocol + '//portal.luxianghui.com:9999/';
const userModuleUrl = 'https://lxh-file-001.oss-cn-hangzhou.aliyuncs.com/%E7%94%A8%E6%88%B7%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF%20.xlsx';
const socketUrl = root.indexOf('https')>=0? 'wss://h5.luxianghui.com/' :  'ws://h5.luxianghui.com:9999/';
const h5BaseUrl = root.indexOf('https')>=0? 'https://h5.luxianghui.com/' :  'http://h5.luxianghui.com/';
export default {
  basePath,pagebasePath,userModuleUrl,socketUrl,h5BaseUrl
}
</script>
