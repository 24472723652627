export default {
    path: 'activity',
    meta: {
        title: '活动管理'
    },
    component: r => require.ensure([], () => r(require('../../portalPage/activity/main')), 'portalActivity'),
    children: [
        {
            path: '',
            redirect: 'list'
        },
        {
            name: 'portalActivityList',
            path: 'list',
            meta:{
                title:'活动列表'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/activity/list')), 'portalActivityList')
        },
        {
            name: 'portalActivitySign',
            path: 'sign',
            meta:{
                title:'签到表'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/activity/sign')), 'portalActivitySign')
        },
        {
            name: 'portalActivityOrder',
            path: 'order',
            meta:{
                title:'名单'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/activity/order')), 'portalActivityOrder')
        },
        {
            name: 'portalActivityInteract',
            path: 'interact',
            meta:{
                title:'互动'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/activity/interact')), 'portalActivityInteract')
        },
        {
            name: 'portalActivityEdit',
            path: 'edit',
            meta:{
                title:'活动编辑'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/activity/edit')), 'portalActivityEdit')
        },
        {
            name: 'portalActivityCensus',
            path: 'census',
            meta:{
                title:'活动数据'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/activity/census')), 'portalActivityCensus')
        },
        {
            name: 'roomTable',
            path: 'roomTable',
            meta:{
                title:'活动数据'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/activity/table')), 'portalActivityCensus')
        }

        
        
    ]
}