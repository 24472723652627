import Vue from 'vue'
import Router from 'vue-router'
import notFound from '@/page/notFound'

// 加载portal路由
import portalRoute from './portal/main'
import portalMain from '@/portalPage/main'
import portalLogin from '@/portalPage/login'
import portalRegister from '@/portalPage/register'
import portalIndex from '@/portalPage/index'
import baseVue from '@/components/base'
// 加载portal路由 end

// 引用API文件
import api from '../util/api'
import {Message} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Router)

const router = new Router({
    mode: 'history',
    //base: 'p',
    routes: [
        {
            path: '',
            redirect: '/portal'
        },
        {
            path: '/portal/login',
            name: 'portalLogin',
            component: portalLogin,
            beforeEnter(to, from, next) {
                localStorage.removeItem('portalToken');
                next();
            }
        },
        {
            path: '/portal/password',
            name: 'portalPassword',
            component: portalLogin,
            beforeEnter(to, from, next) {
                // localStorage.removeItem('portalToken');
                next();
            }
        },
        {
            path: '/portal/register',
            name: 'portalRegister',
            component: portalRegister,
            beforeEnter(to, from, next) {
                localStorage.removeItem('portalToken');
                next();
            }
        },
        {
            path: '/portal',
            name: '',
            component: portalMain,
            children: [
                ...portalRoute.routes,
                {
                    path: '',
                    name: 'portalIndex',
                    component: portalIndex,
                        meta: {
                                keepAlive: true
                        },
                }
               
            ],
            beforeEnter(to, from, next) {
                // if(!localStorage.getItem('token')){
                //     next({
                //         name: 'login',
                //         query: {
                //             gourl: to.fullPath
                //         }
                //     });
                // }else{
                //     next();
                // }
                next();

            }
        },
        {
            path: '*',
            name: 'notFound',
            component: notFound
        }
    ],
    scrollBehavior(to, from, savedPosition) {
        var element = document.getElementsByClassName('main-page-view')[0];
        if (element) {
            element.scrollTop = 0;
        }
        return { x: 0, y: 0 };
    }

})

router.beforeEach((to, from, next) => {
    api.post({
        url: 'api-portal/visitsHistory',
        data: {url: location.href}
    })
    if(!!window.ActiveXObject || "ActiveXObject" in window){//是否是ie浏览器
        Message.warning('当前使用的ie浏览器存在兼容问题，建议使用Chrome或者Safari浏览器访问')
    }
    if(process.env.NODE_ENV === 'production'){//生产环境
        if(location.protocol==='http:'){
           location.protocol='https:';
            // return;
        }
    }

    //设置设备id
    var deviceId = baseVue.getuuid(16);
    if (!localStorage.getItem("device_id")) {
        localStorage.setItem("device_id", deviceId);
    }

    // 路由拦截
    if (to.path == "/portal/activity/edit?isLive=1") {
        api.post({
            url: 'api-activity/getPartnersSumDataUsage',
            success: res => {
                // console.log(res);
                if(res && res > 0) {
                    next();
                } else {
                    this.$confirm('当前账户流量不足，是否立即去充值?', '提示', {
                    confirmButtonText: '确定',
                    type: 'warning'
                    }).then(() => {
                        next({
                            path: '/portal/live/flow',
                        });
                    }).catch(() => {});
                }
            },
            fail: res => {
            }
        })
    } else {
        if(to.path == "/portal" || to.path == "/portal/account/info" || to.path == "/portal/login" || to.path == "/portal/password" || to.path == "/portal/register") {
            next()
        } else {
            api.post({
                url: 'api-portal/getPartnersDetail',
                success: res => {
                    // console.log(res);
                    if(res && res.status) {
                        if(res.status == 2) {
                            next();
                        } else {
                            if(res.status == 3 && to.path == '/portal/account/edit') {
                                next();
                            } else {
                                next('/portal/account/info')
                                Message.warning('您的资料正在审核中，请耐心等待审核通过')
                            }
                        }
                    } else {
                        next();
                    }

                },

            })
        }
    }

})

export default router
