export default {
    path: 'service',
    meta: {
        title: '增值服务'
    },
    component: r => require.ensure([], () => r(require('../../portalPage/service/main')), 'portalService'),
    children: [
        {
            path: '',
            redirect: 'list'            
        },
        {
            name: 'portalServiceList',
            path: 'list',
            meta:{
                title:'增值服务'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/service/list')), 'portalServiceList')
        },
        {
            name: 'portalServiceFunc',
            path: 'func',
            meta:{
                title:'平台服务'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/service/func')), 'portalServiceFunc')
        },
        {
            name: 'portalServiceMeal',
            path: 'meal',
            meta:{
                title:'付费套餐'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/service/meal')), 'portalServiceMeal')
        },
        {
            name: 'portalServiceMealnew',
            path: 'meal_new',
            meta:{
                title:'付费套餐'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/service/meal_new')), 'portalServiceMealnew')
        },
        {
            name: 'portalServiceMealorder',
            path: 'meal_order',
            meta:{
                title:'付费套餐订单'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/service/meal_order')), 'portalServiceMealorder')
        },
        {
            name: 'portalServiceOrder',
            path: 'order',
            meta:{
                title:'服务订单'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/service/order')), 'portalServiceOrder')
        },
        {
            name: 'portalServiceChannel',
            path: 'channel',
            meta:{
                title:'渠道推广'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/service/channel')), 'portalServiceChannel')
        },
        {
            name: 'portalServiceSell',
            path: 'sell',
            meta:{
                title:'分销'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/service/sell')), 'portalServiceSell')
        },
        
        
        
        
    ]
}