<template>
    <div class="reg_page">
        <div class="reg_header">
            <div @click="bind2Index" class="reg_header_left">
                <img class="lxh_logo" src="/static/images/portal/lxh_logo_blue.png" alt="陆想汇商家后台">
                <span class="header_tips">陆想汇商家后台</span>
            </div>
        </div>
        <div class="reg_step">
            <div class="reg_step_item" :class="{'step_active': regStep == 1,'step_did': regStep > 1}">
                <div class="step_item_num">1</div>
                <span>账号信息</span>
            </div>
            <div class="reg_step_line" :class="regStep > 1?'line_active':''"></div>
            <div class="reg_step_item" :class="{'step_active': regStep == 2,'step_did': regStep > 2}">
                <div class="step_item_num">2</div>
                <span>企业信息</span>
            </div>
            <div class="reg_step_line" :class="regStep > 2?'line_active':''"></div>
            <div class="reg_step_item" :class="{'step_active': regStep == 3,'step_did': regStep > 3}">
                <div class="step_item_num">3</div>
                <span>注册成功</span>
            </div>
        </div>

        <div class="reg_form">
            <el-form @submit.native.prevent 
                :model="registerForm" 
                :rules="registerRules" 
                :disabled="regFlag || regStep!=1"
                ref="registerForm"
                label-position="left"
                label-width="100px"
                v-show="regStep==1">
                <el-form-item class="reg_form_item" label="手机号" prop="userMobile">
                    <div class="reg_form_right">
                        <el-input class="reg_input reg_input_short" v-model="registerForm.userMobile" placeholder="输入手机号" ></el-input>
                        <el-button @click="bindGetCode" class="reg_code_btn"><span v-if="regMessageTime">{{regMessageTime}}s</span><span v-else>获取验证码</span></el-button>
                    </div>
                </el-form-item>
                <el-form-item class="reg_form_item" label="验证码" prop="valiCode">
                    <div class="reg_form_right">
                        <el-input class="reg_input" v-model="registerForm.valiCode" placeholder="输入验证码"></el-input>                    
                    </div>
                </el-form-item>
                <el-form-item class="reg_form_item" label="密码" prop="password">
                    <div class="reg_form_right">
                        <el-input class="reg_input" type="password" v-model="registerForm.password" placeholder="输入密码"></el-input>                     
                    </div>
                </el-form-item>
                <el-form-item class="reg_form_item" label="确认密码" prop="password2">
                    <div class="reg_form_right">
                        <el-input class="reg_input" type="password" v-model="registerForm.password2" placeholder="再次输入密码"></el-input>                  
                    </div>
                </el-form-item>
                <el-form-item class="reg_form_item" style="margin-top:50px;">
                    <div class="reg_form_right">
                        <el-button :loading="regFlag" @click="regHandler" class="reg_btn">注册</el-button>
                        <div class="reg_tips"><span>点击注册即代表同意</span><span @click="switchPubRule" class="cr_blue">《商户注册协议》</span></div>                                              
                    </div>
                </el-form-item>
            </el-form>
            <el-form @submit.native.prevent 
                :model="infoForm" 
                :rules="infoFormRules" 
                :disabled="infoFlag || regStep!=2"
                ref="infoForm"
                label-position="left"
                label-width="100px"
                v-show="regStep==2">
                <el-form-item class="reg_form_item" label="企业全称" prop="companyName">
                    <div class="reg_form_right">
                        <el-input class="reg_input" v-model="infoForm.companyName" placeholder="输入完整的企业名称"></el-input>                    
                    </div>
                </el-form-item>
                <el-form-item class="reg_form_item" label="企业简称" prop="sellerName">
                    <div class="reg_form_right">
                        <el-input class="reg_input" v-model="infoForm.sellerName" placeholder="输入企业简称"></el-input>                    
                    </div>
                </el-form-item>
                <el-form-item class="reg_form_item" label="上传logo" prop="logoUrl">
                    <div class="reg_form_right">
                        <el-upload
                            class="reg_img_uploader"
                            action=""
                            v-loading="uploadFlag"
                            :disabled="uploadFlag"
                            :http-request="uploadImg"
                            :before-upload="beforeUploadImg"
                            :show-file-list="false">
                            <div class="reg_img_uploader_left">
                                <img v-if="infoForm.logoUrl" :src="infoForm.logoUrl" class="reg_info_img">
                                <i v-else class="el-icon-plus reg_info_img_icon"></i>
                            </div>
                            <div class="reg_img_uploader_right" slot="tip">只能上传jpg/png文件,建议尺寸1:1</div>
                        </el-upload>                 
                    </div>
                </el-form-item>
                <el-form-item class="reg_form_item" label="行业" prop="industry">
                    <div class="reg_form_right">
                        <el-select class="reg_input" :popper-append-to-body="false" v-model="infoForm.industry" placeholder="选择行业">
                            <el-option
                            v-for="item in industryList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>                 
                    </div>
                </el-form-item>
                <el-form-item class="reg_form_item" label="类型" prop="sellerType">
                    <div class="reg_form_right">
                        <el-select class="reg_input" :popper-append-to-body="false" v-model="infoForm.sellerType" placeholder="选择类型">
                            <el-option
                            v-for="item in sellerTypeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>               
                    </div>
                </el-form-item>
                <el-form-item class="reg_form_item" label="地区" prop="addressArray">
                    <div class="reg_form_right">
                        <el-cascader class="reg_input" 
                        v-model="infoForm.addressArray"
                        :props="{
                            lazy: true,
                            lazyLoad: bindLazyLoad
                        }"></el-cascader>           
                    </div>
                </el-form-item>
                <!-- <el-form-item class="reg_form_item" label="地区" prop="companyProvinceId">
                    <div class="reg_form_right">
                        <el-select class="reg_input" :popper-append-to-body="false" v-model="infoForm.companyProvinceId" placeholder="选择地区">
                            <el-option
                            v-for="item in provinceList"
                            :key="item.provinceId"
                            :label="item.provinceName"
                            :value="item.provinceId">
                            </el-option>
                        </el-select>                   
                    </div>
                </el-form-item> -->
                <el-form-item class="reg_form_item" label="详细地址" prop="companyDetailAddress">
                    <div class="reg_form_right">
                        <el-input class="reg_input" v-model="infoForm.companyDetailAddress" placeholder="输入详细地址"></el-input>                    
                    </div>
                </el-form-item>
                <el-form-item class="reg_form_item" label="联系人" prop="contacter">
                    <div class="reg_form_right">
                        <el-input class="reg_input" v-model="infoForm.contacter" placeholder="输入联系人姓名"></el-input>                    
                    </div>
                </el-form-item>
                <el-form-item class="reg_form_item" label="联系人手机号" prop="contacterTel">
                    <div class="reg_form_right">
                        <el-input class="reg_input" v-model="infoForm.contacterTel" placeholder="输入联系人手机号"></el-input>                    
                    </div>
                </el-form-item>
                <el-form-item class="reg_form_item" label="邮箱" prop="contacterEmail">
                    <div class="reg_form_right">
                        <el-input class="reg_input" v-model="infoForm.contacterEmail" placeholder="输入邮箱"></el-input>                    
                    </div>
                </el-form-item>
                <el-form-item class="reg_form_item" label="法人" prop="legalRepresentative">
                    <div class="reg_form_right">
                        <el-input class="reg_input" v-model="infoForm.legalRepresentative" placeholder="输入公司法人姓名"></el-input>                    
                    </div>
                </el-form-item>
                <el-form-item class="reg_form_item" label="营业执照号" prop="license">
                    <div class="reg_form_right">
                        <el-input class="reg_input" v-model="infoForm.license" placeholder="输入营业执照号"></el-input>                    
                    </div>
                </el-form-item>
                <el-form-item class="reg_form_item" label="营业执照" prop="licenseImg">
                    <div class="reg_form_right">
                        <el-upload
                            class="reg_img_uploader2"
                            action=""
                            v-loading="uploadFlag"
                            :disabled="uploadFlag"
                            :http-request="uploadLicenseImg"
                            :show-file-list="false"
                            >
                            <img v-if="infoForm.licenseImg" :src="infoForm.licenseImg" class="reg_img_uploader2_img">
                            <div v-else class="reg_img_uploader2_icon">
                                <i class="el-icon-plus"></i>
                            </div>
                        </el-upload>                 
                    </div>
                </el-form-item>
                <el-form-item class="reg_form_item" label="企业简介" prop="contacterBrief">
                    <div class="reg_form_right">
                        <el-input class="reg_textarea" type="textarea" :rows="5" maxlength="3000" show-word-limit v-model="infoForm.contacterBrief" placeholder="输入企业简介"></el-input>                    
                    </div>
                </el-form-item>
                <el-form-item class="reg_form_item" style="margin-top:50px;">
                    <div class="reg_form_right">
                        <el-button :loading="infoFlag" @click="reg2Handler" class="reg_btn reg_info_btn">下一步</el-button>                                            
                    </div>
                </el-form-item>
            </el-form>
            
        </div>
        <div v-show="regStep == 3" class="reg_success_module">
            <!-- <i class="el-icon-success reg_success_icon"></i> -->
            <img class="reg_success_img" src="/static/images/portal/login/time.png">
            <div class="reg_success_tips">注册成功,等待审核</div>
            <div class="reg_success_tips2">请耐心等待审核，我们将会在1个工日内为您处理</div>
            <div class="reg_success_btn">
                <el-button type="primary" size="small">平台服务</el-button>                                                          
                <el-button type="primary" plain @click="bind2Login" size="small">直接登录</el-button>                                                            
            </div>
            <div class="reg_success_service">
                <div class="service_title">5G时代 活动营销整体解决方案</div>
                <div class="service_tips">全行业、全领域、全平台整体服务，专注于为金融机构提供活动营销一站式解决方案</div>
                <div @click="goPage('/portal/service/list')" class="service_item">
                    <img class="service_img" src="/static/images/portal/login/reg_service_01.png">
                    <div class="service_name">营销推广</div>
                    <div class="service_shadow">
                        <div class="service_shadow_text">想提高活动曝光、报名量？</div>
                        <div class="service_shadow_text service_shadow_text_bot">点击咨询/购买推广服务套餐→</div>
                    </div>
                </div>
                <div @click="goPage('/portal/account/invite')" class="service_item">
                    <img class="service_img" src="/static/images/portal/login/reg_service_02.png">
                    <div class="service_name">嘉宾邀约</div>
                    <div class="service_shadow">
                        <div class="service_shadow_text">想邀请行业大咖？</div>
                        <div class="service_shadow_text service_shadow_text_bot">点击在线提交邀约嘉宾需求→</div>
                    </div>
                </div>
                <div @click="contactDialog = true" class="service_item">
                    <img class="service_img" src="/static/images/portal/login/reg_service_03.png">
                    <div class="service_name">会务服务 </div>
                    <div class="service_shadow">
                        <div class="service_shadow_text">想委托专业团队举办活动？</div>
                        <div class="service_shadow_text service_shadow_text_bot">点击联系客服咨询详情→</div>
                    </div>
                </div>
                <div @click="contactDialog = true" class="service_item">
                    <img class="service_img" src="/static/images/portal/login/reg_service_04.png">
                    <div class="service_name">技术支持</div>
                    <div class="service_shadow">
                        <div class="service_shadow_text">想拥有针对性的解决方案？</div>
                        <div class="service_shadow_text service_shadow_text_bot">点击联系客服咨询详情→</div>
                    </div>
                </div>
            </div>
        </div>
        <div style="height: 40px;"></div>
       
        <!-- 联系弹窗 -->
        <el-dialog
            title="联系客服"
            :visible.sync="contactDialog"
            width="500px">
            <div class="portal_header_contact">
                <img class="portal_header_contact_img" src="/static/images/portal/wx_contact_code.png" alt="联系我们">
                <div class="portal_header_contact_tips">↑ 扫码添加客服微信</div>
                <div class="portal_header_contact_bot">客服电话：17301836228</div>
            </div>
        </el-dialog>
        <!-- 联系弹窗 end -->
        <!-- 通用协议组件 -->
        <pubRule ref="pubRuleDialog"/>
        <!-- 通用协议组件 end -->
        <!-- 图片裁剪组件 -->
        <cropper ref="imgCropper" :cropperData='cropperData' @bindCropper="bindCropper"></cropper>
        <!-- 图片裁剪组件 end -->
        <div style="display:none;position: absolute;width: 100%;bottom: 0;text-align: center;color: #666666;font-size: 14px;overflow: hidden;line-height: 20px;padding: 10px 0;"> 
            <a href="http://beian.miit.gov.cn/" target="_blank"><div style="text-decoration: none;" class="footer_bot">© Copyright&nbsp;&nbsp;2019&nbsp;&nbsp;陆想汇&nbsp;&nbsp;沪ICP备14052508号-2&nbsp;&nbsp;&nbsp;&nbsp;All Rights Reserved 上海湛融金融信息服务有限公司</div></a>
        </div>
    </div>
</template>
<script>
import cropper from '@/components/cropper.vue';//图片裁剪组件
import pubRule from '@/components/pubRule.vue';//推广协议组件
export default {
    components: {cropper, pubRule},
    created(){
        this.pageConfig();
    },
    data() {
        return {
            goUrl: this.$route.query.goUrl,
            regStep: 1,
            regMessageTime: 0,
            regMessageInterval: 0,
            uploadFlag: false,
            regFlag: false,
            infoFlag: false,
            contactDialog: false,
            cropperData: {//裁剪图片字段
                imgUrl: '',
                label: '',
                fixedNumber: [1,1],
            },
            registerForm:{
                userMobile: '',
                password: '',
                password2: '',
                valiCode: '',
            },
            infoForm: {
                companyName: '',
                sellerName: '',
                logoUrl: '',
                industry: '',
                sellerType: '',
                addressArray: [],//省市区
                companyProvinceId: '',
                companyCityId: '',
                companyCountyId: '',
                companyDetailAddress: '',
                contacter: '',
                contacterTel: '',
                contacterEmail: '',
                legalRepresentative: '',
                license: '',
                licenseImg: '',
                contacterBrief: '',
            },
            industryList: [
                {label: '金融',value: 55},
                {label: '教育培训',value: 56},
                {label: '医疗健康',value: 57},
                {label: '消费生活',value: 58},
                {label: '电子商务',value: 59},
                {label: '社交网络',value: 60},
                {label: '媒体',value: 61},
                {label: '广告营销',value: 62},
                {label: '游戏动漫',value: 63},
                {label: '智能硬件',value: 64},
                {label: '工具软件',value: 65},
                {label: '企业服务',value: 66},
                {label: '旅游户外',value: 67},
                {label: '房产家居',value: 68},
                {label: '文体艺术',value: 69},
                {label: '汽车交通',value: 70},
                {label: '物流',value: 71},
                {label: '其他',value: 72},
            ],
            sellerTypeList: [
                {label: '媒体机构',value: 5},
                {label: '研究机构',value: 1},
                {label: '投资机构',value: 2},
                {label: '销售机构',value: 3},
                {label: '自媒体',value: 4},
                {label: '服务机构',value: 6},
                {label: '其他',value: 7},
            ],
            infoFormRules: {
                companyName: [
                    { required: true, message: '请输入企业全称', trigger: 'blur' }
                ],
                sellerName: [
                    { required: true, message: '请输入企业简称', trigger: 'blur' }
                ],
                logoUrl: [
                    { required: true, message: '请上传logo', trigger: 'change' }
                ],
                industry: [
                    { required: true, message: '请选择行业', trigger: 'change' }
                ],
                sellerType: [
                    { required: true, message: '请选择企业类型', trigger: 'change' }
                ],
                addressArray:[
                    {type: 'array',required:true, message:'请选择地区',trigger: 'change'},
                ],
                companyDetailAddress: [
                    { required: true, message: '请输入详细地址', trigger: 'blur' }
                ],
                contacter: [
                    { required: true, message: '请输入联系人姓名', trigger: 'blur' }
                ],
                contacterTel: [
                    { required: true, message: '请输入联系人手机号码', trigger: 'blur' },
                    { validator:(rule, value, callback)=>{
                        // console.log(value)
                        if (!this.$isPhone(value)){
                            callback(new Error('请输入正确的手机号'));
                        } else{
                            callback();
                        }
                    }, trigger: ['change','blur'] }
                ],
                contacterEmail: [
                    { required: true, message: '请输入联系人邮箱', trigger: 'blur' },
                    { type:'email',message:'邮箱格式不正确',trigger: 'blur'}
                ],
                legalRepresentative: [
                    { required: true, message: '请输入公司法人姓名', trigger: 'blur' }
                ],
                license: [
                    { required: true, message: '请输入营业执照', trigger: 'blur' }
                ],
                licenseImg: [
                    { required: true, message: '请上传营业执照', trigger: 'change' }
                ],
                contacterBrief: [
                    { required: true, message: '请输入企业简介', trigger: 'blur' }
                ],
            },
            registerRules:{
                userMobile: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { type:'string',pattern:/^1[0-9]{10}$/,message:'手机号格式不正确',trigger: 'blur'}
                ],
                valiCode: [
                    { required: true, message: '请输入验证码', trigger: 'blur' }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min:6,max:20, message: '密码6-20位', trigger: 'blur' }
                ],
                password2: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min:6,max:20, message: '密码6-20位', trigger: 'blur' },
                    { validator:(rule, value, callback)=>{
                        if(this.registerForm.password2 !== this.registerForm.password){
                            callback(new Error('两次密码输入不一致'));
                        }else{
                            callback();
                        }
                    }, trigger: 'blur' }
                ],
            },
        };
    },
    methods: {
        pageConfig() {
            try { 
                this.$wxShare({
                    pageConfigUrl: 'api-activity/wxShare',
                    shareImgUrl: 'https://lxh-image-001.oss-cn-hangzhou.aliyuncs.com/share_logo.png',
                    shareTitle: '陆想汇商家注册链接',
                    shareDesc: '一键免费发布活动、直播、文章获取你的专属私域流量。'
                })
            } catch (error) {
                
            }
        },
        switchPubRule() {
            this.$refs.pubRuleDialog.switchRuleDialog('reg');
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        bind2Login() {
            this.$router.push('/portal/login')
        },
        bind2Index() {
            this.$router.push('/portal')
        },
        goPage(url) {//页面跳转url
            this.$router.push(url)
        },
        bindLazyLoad(node, resolve) {
            // console.log(node)
            if(node.level == 0){
                this.$api.post({
                    url:'api-activity/listProvince',
                    success:res=>{
                        // console.log('省份列表：',res);
                        let list = res.provinceList;
                        for(var i in list) {
                            list[i].value = 'p' + list[i].provinceId;
                            list[i].label = list[i].provinceName;
                            list[i].leaf = false;
                        }
                        resolve(list)
                    }
                })
            }
            if(node.level == 1){
                this.$api.post({
                    url:'api-activity/listCitys',
                    data: {provinceId: node.value.substr(1)},
                    success:res=>{
                        // console.log('市列表：',res);
                        let list = res.cityList;
                        for(var i in list) {
                            list[i].value = 'c' + list[i].cityId;
                            list[i].label = list[i].cityName;
                            list[i].leaf = false;
                        }
                        resolve(list)
                    }
                })
            }
            if(node.level == 2){
                this.$api.post({
                    url:'api-activity/listDistricts',
                    data: {cityId: node.value.substr(1)},
                    success:res=>{
                        // console.log('区列表：',res);
                        let list = res.districtList;
                        for(var i in list) {
                            list[i].value = 'd' + list[i].districtId;
                            list[i].label = list[i].districtName;
                            list[i].leaf = true;
                        }
                        resolve(list)
                    }
                })
            }
        },
        beforeUploadImg(file) {//裁剪上传logo
            this.uploadFlag = true;
            let blob1 = URL.createObjectURL(file);
            this.cropperData.imgUrl = blob1;
            this.cropperData.label = 'logoUrl';
            this.$refs.imgCropper.switchCropperDialog();
            URL.revokeObjectURL(file);
            this.uploadFlag = false;
        },
        uploadImg(obj){//上传logo
            return false;
            // this.uploadFlag = true;
            // this.$base.aliupload(obj.file,'image',(res) => {
            //     console.log(res)
            //     this.uploadFlag = false;
            //     this.infoForm.logoUrl = res;
            // });
        },
        bindCropper(res) {//截图返回结果，type:0为取消截图，type:1为截图成功
            if(res.type) {
                this.infoForm[res.label] = res.data;
            }
        },
        uploadLicenseImg(obj) {
            this.uploadFlag = true;
            this.$base.aliupload(obj.file,'image',(res) => {
                console.log(res)
                this.uploadFlag = false;
                this.infoForm.licenseImg = res;
            });
        },
        bindGetCode() {
            if(this.regMessageTime){
                return;
            }
            this.$refs.registerForm.validateField('userMobile', valid => {
                if(!valid){
                    const loading = this.$loading({
                        target: '.reg_code_btn',
                        lock: true,
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                    this.$api.post({
                        url: 'api-user/sendTencentMessage',
                        data: {
                            function: 21,
                            userMobile: this.registerForm.userMobile  
                        },
                        success: res => {
                            clearInterval(this.regMessageInterval);
                            this.regMessageTime = 60;
                            this.regMessageInterval = window.setInterval(()=>{
                                this.regMessageTime--;
                                if(this.regMessageTime===0){
                                    clearInterval(this.regMessageInterval);
                                }
                            },1000);
                            loading.close();
                        },
                        fail: res => {
                            this.$message({
                                message: res.data.cnMsg,
                                type: 'error'
                            });
                        }
                    })
  
                }
            })
        },
        regHandler(){
            if(this.regFlag) return;
            this.$refs.registerForm.validate(valid => {
                if (valid) {
                    this.regFlag = true;
                    this.$api.post({
                        url:'api-portal/checkPortalMessage',
                        data: {
                            userMobile: this.registerForm.userMobile,
                            valiCode: this.registerForm.valiCode
                        },
                        success: res => {
                            this.regStep = 2;
                        },
                        fail: res => {
                            this.$message({
                                message: res.data.cnMsg || '注册失败,请稍后再试',
                                type: 'error'
                            });
                        },
                        complete: res => {
                            this.regFlag = false;
                        }
                    });
                } else { 
                    return false;
                    
                }
            });
        },
        reg2Handler() {
            if(this.infoFlag) return;
            this.$refs.infoForm.validate(valid => {
                if (valid) {
                    this.infoFlag = true;
                    let infoData = this.$base.deepClone(this.infoForm);
                    if(infoData.addressArray && infoData.addressArray.length) {//处理省市区信息
                        infoData.companyProvinceId = infoData.addressArray[0].substr(1);
                        infoData.companyCityId = infoData.addressArray[1].substr(1);
                        infoData.companyCountyId = infoData.addressArray[2].substr(1);
                    }
                    delete infoData.addressArray;
                    this.$api.post({
                        url:'api-portal/savePartners',
                        data: {
                            ...{
                            userMobile: this.registerForm.userMobile,
                            valiCode: this.registerForm.valiCode,
                            loginPwd: this.registerForm.password,
                            },
                            ...infoData
                        },
                        success: res => {
                            this.regStep = 3;
                        },
                        fail: res => {
                            this.$message({
                                message: res.data.cnMsg || '注册失败,请稍后再试',
                                type: 'error'
                            });
                        },
                        complete: res => {
                            this.infoFlag = false;
                        }
                    });
                } else { 
                    return false;
                    
                }
            });
        },

    }
  }
</script>
<style>
    html{
        height: 100%;
        overflow-y: auto!important;
    }
    body{
        overflow-y: auto!important;
        height: 100%;
        margin: 0;
    }
    #cropperElemlogoUrl{
        height: 400px;
        width: 400px;
    }
    .portal_header_contact_img{
        display: block;
        height: 160px;
        width: 160px;
        margin: 40px auto 10px;
    }   
    .portal_header_contact_tips{
        text-align: center;
        font-size: 16px;
        color: #333333;
        line-height: 20px;
    }
    .portal_header_contact_bot{
        font-size: 16px;
        color: #333333;
        font-weight: 500;
        text-align: center;
        line-height: 20px;
        margin-top: 30px;
        padding: 20px 0;
        border-top: 1px solid #f2f2f2;
    }
</style>
<style lang="scss" scope>
    @import '@/style/portal/register.scss';
</style>


