export default {
    path: 'course',
    meta: {
        title: '课程管理'
    },
    component: r => require.ensure([], () => r(require('../../portalPage/course/main')), 'portalCourse'),
    children: [
        {
            path: '',
            redirect: 'list'            
        },
        {
            name: 'portalCourseList',
            path: 'list',
            meta:{
                title:'课程列表'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/course/list')), 'portalCourseList')
        },
        {
            name: 'portalCourseEdit',
            path: 'edit',
            meta:{
                title:'课程编辑'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/course/edit')), 'portalCourseEdit')
        },
        {
            name: 'portalCourseClassEdit',
            path: 'classEdit',
            meta:{
                title:'课节编辑'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/course/edit_class')), 'portalCourseClassEdit')
        },
        {
            name: 'portalCourseClass',
            path: 'class',
            meta:{
                title:'课节列表'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/course/class')), 'portalCourseClass')
        },
        {
            name: 'portalCourseOrder',
            path: 'order',
            meta:{
                title:'订单列表'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/course/order')), 'portalCourseOrder')
        },
        
        
        
    ]
}