export default {
    path: 'shop',
    meta: {
        title: '店铺管理'
    },
    component: r => require.ensure([], () => r(require('../../portalPage/shop/main')), 'portalShop'),
    children: [
        {
            path: '',
            redirect: 'list'            
        },
        {
            name: 'portalShopList',
            path: 'list',
            meta:{
                title:'店铺装修'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/shop/list')), 'portalShopList')
        },
        {
            name: 'portalShopEdit',
            path: 'edit',
            meta:{
                title:'店铺装修'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/shop/edit')), 'portalShopEdit')
        },
        {
            name: 'portalShopMember',
            path: 'member',
            meta:{
                title:'会员列表'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/shop/member')), 'portalShopMember')
        },
        {
            name: 'portalShopCollect',
            path: 'collect',
            meta:{
                title:'合集管理'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/shop/collect')), 'portalShopCollect')
        },
        {
            name: 'portalShopCollectEdit',
            path: 'collect_edit',
            meta:{
                title:'合集编辑'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/shop/collect_edit')), 'portalShopCollectEdit')
        },
        
        
    ]
}