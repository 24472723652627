<template>
    <div>
        <el-dialog
            :title="moduleName=='spread'?'活动推广服务协议':'商户注册协议'"
            :visible.sync="pubRuleDialog"
            width="30%"
            >
            <!-- 推广协议 -->
            <div v-show="moduleName=='spread'" class="user_rule_module">
                <div class="rule_name">陆想汇活动推广服务协议</div>
                <div class="rule_content">
                        <div class="content">活动推广服务协议（以下简称“本协议”）是上海湛融金融信息服务有限公司（以下简称“本公司”或“陆想汇”）与机构用户(以下简称“您”或“用户”)就活动推广服务相关事项所订立的有效合约。用户通过网络页面点击确认或以其他方式选择接受本协议，即表示用户与本公司已达成协议并同意接受本协议的全部约定内容以及与本协议有关的各项规则及陆想汇网站所包含的其他与本协议或本协议项下各项规则的条款和条件有关的各项规定。</div>
                        <div class="content">在接受本协议之前，请您仔细阅读本协议的全部内容。如果您不同意本协议的任意内容，或者无法准确理解陆想汇对条款的解释，请不要进行后续操作。</div>
                </div>
                <div class="rule_title">第一条 释义</div>
                <div class="rule_content">
                        <div class="content">活动推广服务：目标活动用户指定本公司为活动的第三方活动合作机构，为用户提供举办活动所需的互联网宣传推广服务，宣传推广形式包括但不限于：微信公众号、首页Banner轮播，活动详情页推荐、短信、社群等。</div>
                </div>
                <div class="rule_title">第二条 约定</div>
                <div class="rule_content">
                        <div class="content">1、您授权陆想汇根据活动的规模以及推广需求对发布活动进行推广。</div>
                        <div class="content">2、您理解并同意，陆想汇有权对活动进行分类并推广，相应的渠道推广费用，具体以页面提示为准。</div>
                        <div class="content">3、您理解并同意，您有义务向陆想汇提供合作活动的信息及各种相关文字、图片介绍资料，以便开展推广工作；在协议期间用户授权陆想汇就合作活动宣传材料发布权（明示不得发布的除外），陆想汇有权利将活动的相关资料（明示不得发布的除外）提供给第三方。</div>
                        <div class="content">4、您理解并同意，陆想汇有义务在约定的时间内将实际收取到、扣除佣金后的销售票款支付给您。</div>
                        <div class="content">5、您理解并同意，您需要保证活动的举行，无论因何种原因而造成活动延期、取消情况，有义务及时通知陆想汇。</div>
                        <div class="content">6、您理解并同意，陆想汇有权利对活动宣传推广材料进行审核评估，并提出修改意见。</div>
                        <div class="content">7、您理解并同意，确保其拥有向陆想汇提供的任何内容素材知识产权或合法授权；出席的嘉宾以获得相应的肖像权许可；陆想汇根据本合同履行义务不会因使用含有您邀请嘉宾和提供册素材而侵犯任何第三方的相关权利。因此而产生纠纷应由您单独负责，并应赔偿陆想汇因此遭受的损失。</div>
                </div>
                <div class="rule_title">第三条 通知</div>
                <div class="rule_content">
                        <div class="content">关于本协议条款通知，陆想汇将以电子形式或纸张形式通知您，包括但不限于依据您提供的电子邮件地址发送电子邮件的方式、依据您提供的联系地址寄送挂号信的方式、于本网站或合作伙伴网站上公布、或发送手机短信和电话通知等方式。您应当确保上述电子邮件地址、联系地址、手机、电话等联系方式始终处于有效使用状态；若发生变化，您应当按照陆想汇网站指定的方式进行变更。上述通知一旦发送将视为有效送达。</div>
                </div>
                <div class="rule_title">第四条 支付服务</div>
                <div class="rule_content">
                        <div class="content">1、商户在陆想汇平台的活动交易费由陆想汇代收，双方均派专人负责票款的结算工作，在活动结束后 14个工作日由内双方核算实际收到的销售票款及佣金服务费，由陆想汇将扣除佣金后的所有实际票款支付给商户，并向商户开具佣金服务费相应金额的增值税发票。</div>
                        <div class="content">2、陆想汇对于发布的免费活动或未涉及收款功能的活动，将继续完全免费的提供平台的基础服务。</div>
                        <div class="content">3、陆想汇对于使用收款功能的商户，在陆想汇平台内产生的付费报名订单，陆想汇将收取每张票券手续费为票款金额*20%元。</div>
                </div>
                <div class="rule_title">第五条 协议修改</div>
                <div class="rule_content">
                        <div class="content">陆想汇保留修改或增补本协议内容的权利。本协议的修改文本将公告于陆想汇网站或以其他可行的方式公告。修改文本一旦公布则立即生效，且对生效前的用户同样适用。</div>
                </div>
                <div class="rule_title">第六条 风险</div>
                <div class="rule_content">
                        <div class="content">因发生不可抗力事件导致服务无法正常提供，陆想汇应及时通知其他协议当事人及相关方并积极采取措施予以解决，但是不承担赔偿责任。不可抗力包括但不限于战争、火灾、地震、水灾、罢工、网络故障、系统故障等不能合理预见、不可避免和不能克服的自然灾害、社会异常事件及技术风险等。</div>
                </div>
                <div class="rule_title">第七条 法律适用</div>
                <div class="rule_content">
                        <div class="content">1、因本协议引起的或与本协议有关的争议，均适用中华人民共和国法律。</div>
                        <div class="content">2、因本协议引起的或与本协议有关的争议，陆想汇与用户协商解决。协商不成的，任何一方均有权提起诉讼，并以被告住所地法院为第一审管辖法院。</div>
                        <div class="content">第七条 本协议作为《陆想汇服务协议》的有效补充，本协议未约定的内容，双方需按照《陆想汇服务协议》行相关约定。</div>
                </div>
            </div>
            <!-- 注册协议 -->
            <div v-show="moduleName=='reg'" class="user_rule_module">
                <div class="rule_name">陆想汇商户注册协议</div>
                <div class="rule_title">一、服务协议的确认和接纳</div>
                <div class="rule_content">
                    <div class="content">陆想汇是由上海湛融金融信息服务有限公司拥有所有权与经营权产品。商户在接受陆想汇的服务之前，请务必仔细阅读本条款并同意本声明。商户访问本网站或通过各类方式使用本网站的行为，都将被视为是对本声明全部内容的无异议的认可。</div>
                    <div class="content">1.1 商户直接或间接通过各类方式产生与本网站相关的行为时，都将被视为已无条件接受本声明所涉及的全部内容；若商户对本声明的任何条款有异议，请停止使用陆想汇所提供的全部服务。</div>
                    <div class="content">1.2 当商户使用陆想汇的服务时，需遵守中国的法律法规并保证信息的真实性，同时应当恪守以下承诺：</div>
                    <div class="content"><span class="empty_span"></span>1)陆想汇在修改条款时不再另行通知商户，商户可随时通过陆想汇网站浏览最新服务协议条款。商户只有在同意遵守相关法律法规和本协议时，才有权使用陆想汇的服务，无论商户是否有意访问或使用此服务。</div>
                    <div class="content"><span class="empty_span"></span>2)如果商户不同意陆想汇对协议相关条款的修改，则有权停止使用服务。如果继续使用服务，则视为已接受陆想汇最新服务协议条款。</div>
                    <div class="content"><span class="empty_span"></span>3)商户注册时请按照提示填写正确的手机号、邮箱、姓名和密码，并确保日后更新的相关资料具有有效性和合法性。若商户提交任何违法或者陆想汇认为不适合在其平台上展示的资料，陆想汇则有理由怀疑商户的资料属于恶意操作，并且有权暂停或终止商户的账号。</div>
                    <div class="content"><span class="empty_span"></span>4)陆想汇仅对商户的信息与资料进行形式审查，不进行实质性审查（实质性审查包括但不限于鉴别、核实任何登记资料的真实性、正确性以及是否为最新资料的审查）对此所产生的纠纷陆想汇不承担过错责任。</div>
                    <div class="content"><span class="empty_span"></span>5)商户有保证其信息和资料详尽、准确并对其及进行时更改的义务。</div>
                    <div class="content"><span class="empty_span"></span>6)商户有义务在其注册信息发生变动时，及时更新相关资料以保证在使用该信息时的准确性和合法性。</div>
                </div>
                <div class="rule_title">二、商户使用规则</div>
                <div class="rule_content">
                    <div class="content">2.1 商户有义务保管好所注册账号的相关信息并对以其进行的所有行为负法律责任。商户不应将其账号、密码转让或借予他人使用。因黑客行为或商户的保管疏忽导致账号、密码外泄，陆想汇不承担任何责任。</div>
                    <div class="content">2.2 根据有关法律法规，陆想汇在此郑重提醒商户：陆想汇仅为商户提供活动发布平台，无法控制经由本服务发布的活动现场情况，因此不保证活动是否符合商户的预期。但陆想汇有权依法停止发布任何不符合平台规则的内容并采取相应措施，包括但不限于暂停商户使用本服务，保存有关记录，并向有关机构报告。</div>
                    <div class="content">2.3 商户保证对其通过陆想汇服务发布到网站上的内容拥有完整的合法的知识产权，保证陆想汇使用该内容不违反国家的法律法规，也不侵犯第三方的合法权益或承担任何义务。商户承诺上传的内容不涉及侵犯他人著作权、商标、名誉、隐私及其他一切违法内容。商户应对其所提供内容因形式、内容及授权的不完善、不合法所造成的一切后果承担完全责任。</div>
                    <div class="content">2.4 商户承诺遵循中华人民共和国有关互联网使用以及金融支付的的法律法规，不从事洗钱、诈骗、以及其他任何破坏社会主义经济秩序的活动；商户使用本服务的行为若有任何违反国家法律法规或侵犯第三方的合法权益时，陆想汇将保留向公安机关以及国家有关监管部门举报的权利。</div>
                    <div class="content">2.5 商户在使用陆想汇服务期间，本协议将保持完整的效力。如果商户所发布的活动与法律相抵触或者不符合本协议，陆想汇可以在不发出任何通知的情况下，随时终止商户的注册商户资格。</div>
                </div>
                <div class="rule_title">三、隐私保护</div>
                <div class="rule_content">
                    <div class="content">3.1 商户注册陆想汇账号时，系统会要求其提供相关联系信息：如姓名、电话、邮箱以及账号密码。</div>
                    <div class="content">3.2 商户的信息将在下述情况下部分或全部被披露：</div>   
                    <div class="content"><span class="empty_span"></span>1)经商户同意，向第三方披露；</div>   
                    <div class="content"><span class="empty_span"></span>2)根据有关法律规定，或应行政、司法机构的要求，向第三方或者行政、司法机构披露；</div>   
                    <div class="content"><span class="empty_span"></span>3)如果商户出现违反中国有关法律或者网站政策的情况，需要向第三方披露；</div>   
                    <div class="content"><span class="empty_span"></span>4)为提供商户所要求的商品和服务，而必须和第三方分享商户的信息；</div>   
                    <div class="content"><span class="empty_span"></span>5)其他陆想汇根据法律或者网站政策认为必要的披露。</div>   
                    <div class="content">3.3 请商户妥善保管账户及密码信息。如果商户发现信息泄密，尤其是陆想汇的账户和密码泄露，请立即联络陆想汇客服，以便陆想汇采取相应措施。</div>    
                </div>
                <div class="rule_title">四、服务内容介绍</div>
                <div class="rule_content">
                    <div class="content">4.1 网络信息服务：指活动主办方根据本协议的规定使用陆想汇发布活动信息、作为主办方和参与者确认票种交易信息、商户参加陆想汇的有关活动以及其他由陆想汇同意提供的信息服务。</div>
                    <div class="content">4.2 陆想汇平台群发信息服务：指陆想汇平台为商业提供的活动推广和通知邀请等服务。</div>
                    <div class="content">4.3 其他服务：包括但不限于商业推广、广告等服务，由协议方在补充协议中确定。商户在此同意陆想汇在任何情况下都无需向其或第三方在传播过程中的不准确或疏漏而导致的损害承担赔偿责任。</div>
                </div>
                <div class="rule_title">五、知识产权</div>
                <div class="rule_content">
                    <div class="content">商户在陆想汇发布的内容应保证其为著作权人或已取得合法授权，并且该内容不会侵犯任何第三方的合法权益，商户同意授予陆想汇所有上述内容在全球范围内的免费、不可撤销的的使用权许可，据该许可陆想汇将有权以展示、推广及其他不为我国法律所禁止的方式使用前述内容。</div> 
                </div>
                <div class="rule_title">六、禁止发布的内容</div>
                <div class="rule_content">
                    <div class="content">6.1 请商户谨慎选择发布在陆想汇平台的信息，根据相关法律法规，任何组织和个人都不得利用陆想汇发布以下内容：</div>
                    <div class="content"><span class="empty_span"></span>1)反对宪法所确定的基本原则的；</div>   
                    <div class="content"><span class="empty_span"></span>2)危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</div>   
                    <div class="content"><span class="empty_span"></span>3)损害国家荣誉和利益的；</div>   
                    <div class="content"><span class="empty_span"></span>4)煽动民族仇恨、民族歧视，破坏民族团结的；</div>   
                    <div class="content"><span class="empty_span"></span>5)破坏国家宗教政策，宣扬邪教和封建迷信的；</div>   
                    <div class="content"><span class="empty_span"></span>6)散布谣言，扰乱社会秩序，破坏社会稳定的；</div>   
                    <div class="content"><span class="empty_span"></span>7)散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</div>   
                    <div class="content"><span class="empty_span"></span>8)侮辱或者诽谤他人，侵害他人合法权益的；</div>   
                    <div class="content"><span class="empty_span"></span>9)含有法律、行政法规禁止的其他内容的信息。</div>   
                    <div class="content"><span class="empty_span"></span>如果商户知道任何人违反上述规定，请直接联系陆想汇客服。陆想汇不对任何商户提供的资料承担责任。</div>   
                    <div class="content">6.2 除上述一般条款的规定，如下列出了部分在陆想汇发布后被设为私有的活动类型：</div>
                    <div class="content"><span class="empty_span"></span>1)批量重复发布同一活动，重复的活动将被设置为私有状态；</div>   
                    <div class="content"><span class="empty_span"></span>2)明显销售性质、虚假宣传、招商（生）、征集项目、留学（投资）咨询、销售产品、线上活动、展览、相亲（恋爱交友）、医疗、美容、众筹、宗教类等等其他类型暂时都会被设置为私有状态；</div>   
                    <div class="content"><span class="empty_span"></span>3)无具体活动内容的英语培训、相亲交友、演讲口才等营销类活动将被设置为私有状态；</div>   
                    <div class="content"><span class="empty_span"></span>4)展会招商类广告，都将被设置为私有状态。</div>   
                </div>
                <div class="rule_title">七、风险与免责</div>
                <div class="rule_content">
                    <div class="content">商户通过本网站与广告商或其他第三方进行任何形式的通讯或商业往来，或参与活动，包含相关服务的付款，以及达成的其它任何条款，完全为商户与广告商或其他第三方之间之行为。商户因前述任何交易或前述广告商及其他第三方而遭受的任何性质的损失或损害，陆想汇对此不负任何法律责任。</div>
                    <div class="content">7.1 基于互联网的开放性和社交网络服务的传播特殊性，陆想汇特别提醒商户谨慎注意以下风险：</div>
                    <div class="content"><span class="empty_span"></span>1)本服务仅提供在线活动管理平台，商户应当对其他商户使用本服务所发布的内容进行独立判断并承担相应风险，商户了解并确认，陆想汇不对本服务商户之间的纠纷承担任何责任。</div>   
                    <div class="content"><span class="empty_span"></span>2)商户在使用本服务过程中所发布的内容有可能会被第三方修改或做其他用途，脱离商户的预期和控制，商户必须充分意识此类风险的存在，陆想汇对由此产生的纠纷不予负责。</div>   
                    <div class="content">7.2 上海湛融金融信息服务有限公司不担保陆想汇提供给商户的全部信息或从陆想汇发出的信息没有病毒或其他有害成分。</div>
                    <div class="content">7.3 以下情况使您无法使用各项服务时，本公司不承担任何损害赔偿责任，该状况包括但不限于：</div>
                    <div class="content"><span class="empty_span"></span>1)由于商户将商户密码告知他人或与他人共享注册帐户，由此导致的任何信息的泄漏，或其他非因陆想汇原因导致的信息的泄漏；</div>   
                    <div class="content"><span class="empty_span"></span>2)任何由于政府管制而造成的暂时性网站关闭；</div>   
                    <div class="content"><span class="empty_span"></span>3)因不可抗力导致的任何后果；</div>   
                    <div class="content"><span class="empty_span"></span>4)因本公司公告之后系统停机维护期间；</div>   
                    <div class="content"><span class="empty_span"></span>5)因黑客攻击、电信部门技术调整或故障、网站升级、银行、第三方支付工具方面的问题等原因而造成的服务中断或者延迟。</div>      
                    <div class="content">7.4 本协议适用中华人民共和国的法律，所有的争端将诉诸北京市艾科创意信息技术有限公司所在地的人民法院。</div>
                </div>
                <div class="rule_title">八、协议变更、服务变更、中断或终止</div>
                <div class="rule_content">
                    <div class="content">8.1 本服务条款可由陆想汇根据需要随时更新，且毋须另行通知。如果商户不同意相关变更，必须停止使用“服务”。登录或继续使用“服务”将表示商户接受经修订的协议。</div>
                    <div class="content">8.2 鉴于服务的特殊性，商户同意陆想汇有权随时变更、中断或终止服务。如变更、中断或终止的服务属于免费服务，陆想汇无需通知商户，也无需对任何商户或任何第三方承担任何责任。</div>
                    <div class="content">8.3 商户理解陆想汇需要定期或不定期地对提供服务的平台或相关设备进行维护，如因此类情况而造成收费服务在合理时间内的中断，陆想汇无需为此承担任何责任，陆想汇会尽可能事先进行通告。</div>
                    <div class="content">8.4 如发生下列任何一种情形，陆想汇有权随时中断或终止向商户提供本协议项下的服务（包括收费服务）且无需对商户或任何第三方承担任何责任：</div>
                    <div class="content"><span class="empty_span"></span>1）商户提供的个人资料不真实；</div>   
                    <div class="content"><span class="empty_span"></span>2）商户违反本协议中规定的使用规则；</div>   
                    <div class="content"><span class="empty_span"></span>3）商户在使用收费服务时未按规定向陆想汇支付相应的服务费。</div>   
                    <div class="content">8.5 商户同意陆想汇基于自行考虑，因任何理由，包含但不限于缺乏使用，或认为商户已经违反本使用协议，终止商户的账号或本服务的使用，并将商户在本服务内任何内容加以移除。商户同意依本使用协议提供的服务，无需进行事先通知即可中断或终止。陆想汇对商户或任何第三人均不承担任何责任。</div>  
                </div>
                <div class="rule_title">九、适用的法律和管辖权</div>
                <div class="rule_content">
                    <div class="content">9.1 商户同意遵守中华人民共和国相关法律法规的所有规定，并对其在使用本服务过程中产生的结果承担全部责任。同时如果陆想汇有理由认为商户有违反国家法律法规法人行为，陆想汇可在任何时候不经事先通知终止向商户提供服务。</div>
                    <div class="content">9.2 商户因本协议产生的争议，均应依照中华人民共和国法律予以处理，并由上海湛融金融信息服务有限公司所在地的人民法院提起诉讼。</div>  
                </div>
                <div class="rule_title">十、支付服务</div>
                <div class="rule_content">
                    <div class="content">1）商户在陆想汇平台的活动交易费由陆想汇代收，双方均派专人负责票款的结算工作，在活动结束后 14个工作日由内双方核算实际收到的销售票款及佣金服务费，由陆想汇将扣除佣金后的所有实际票款支付给商户，并向商户开具佣金服务费相应金额的增值税发票。</div>
                    <div class="content">2）陆想汇对于发布的免费活动或未涉及收款功能的活动，将继续完全免费的提供平台的基础服务。</div>
                    <div class="content">3）陆想汇对于使用收款功能的商户，在陆想汇平台内产生的付费报名订单，陆想汇将收取每张票券手续费为票款金额*20%元。</div>
                </div>
                <div class="rule_title">十一、其他</div>
                <div class="rule_content">
                    <div class="content">11.1 若商户对本声明的任何条款有异议，请停止使用陆想汇所提供的全部服务。陆想汇修改条款时不再另行通知商户，如果商户继续使用服务，则视为商户接受陆想汇最新服务协议条款。</div>   
                    <div class="content">11.2 本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。</div>   
                    <div class="content">11.3 若商户和陆想汇之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，商户同意将纠纷或争议提交上海湛融金融信息服务有限公司所在地的人民法院管辖。</div>   
                    <div class="content">11.4 本协议所有条款的标题仅为阅读方便，本身并无实际涵义，不能作为本协议涵义解释的依据。</div>   
                    <div class="content">11.5 本协议条款无论因何种原因部分无效或不可执行，其余条款仍有效，对双方具有约束力。</div>   
                    <div class="content">11.6 本服务协议的最终解释权归上海湛融金融信息服务有限公司所有。</div>   
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="pubRuleDialog = false">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<style lang="scss" scope>

</style>
<script>
export default {//通用协议组件
    name: 'pubRule',
    data () {
        return {
           pubRuleDialog: false,
           moduleName: 'spread'
        }
    },
    methods:{
        switchRuleDialog(name,type) {//1:关闭
            if(name) this.moduleName = name;
            if(type) {
                this.pubRuleDialog = false;
            } else {
                this.pubRuleDialog = true;
            }
        }
    }
}
</script>
