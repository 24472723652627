<template>
    <div class="error_page">  
        <div class="error_page_module">
            <img class="error_img" src="/static/images/portal/error_404.png" alt="error">
            <div class="error_tips">啊呀！你访问的页面不小心迷路了~</div>
            <router-link to="/portal" style="text-decoration: none;"><el-button style="display:block;width:200px;margin:30px auto;" type="primary" size="medium">返回首页</el-button></router-link>
        </div>
    </div>
</template>
<style>
.error_page{
    height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden;
    overflow-y: auto;
}
.error_page_module{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-60%);
}
.error_img{
    display: block;
    height: 300px;
    width: 300px;
    margin: 0 auto;
}
.error_tips{
    color: #666666;
    font-size: 26px;
    text-align: center;
    margin-top: 30px;
}
</style>

<script>
export default {
    created(){
    },
    data() {
        return {
           
        };
    },
    methods: {
       
    }
  }
</script>
<style>
    html{
        height: 100%;
        background:#f8f8f8;
    }
    body{
        height: 100%;
        margin:0;
    }
</style>

