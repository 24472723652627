<template>
    <el-dialog
        title="图片裁剪"
        :visible.sync="cropperDialogVisible"
        width="700px"
        :close-on-click-modal='false'
        center>
        <div class="cropper_elem" :id="'cropperElem' + cropperData.label">
            <vueCropper
            ref="cropper"
            :img="cropperData.imgUrl"
            :outputSize="cropperOption.size"
            :outputType="cropperOption.outputType"
            :autoCrop="cropperOption.autoCrop"
            :fixed="cropperOption.fixed"
            :fixedNumber="cropperData.fixedNumber || cropperOption.fixedNumber"
            :fixedBox="cropperOption.fixedBox"
            :mode="cropperOption.mode"
            :maxImgSize="cropperOption.maxImgSize"
            :info='cropperOption.info'
            :centerBox="cropperOption.centerBox"
            ></vueCropper>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" icon="el-icon-zoom-in" plain @click="bindChangeScale(1)">放大</el-button>
            <el-button type="primary" icon="el-icon-zoom-out" plain @click="bindChangeScale(-1)">缩小</el-button>
            <el-button type="primary" :loading="isCropperLoading" @click="cropperHandler">&nbsp;&nbsp;&nbsp;&nbsp;截取&nbsp;&nbsp;&nbsp;&nbsp;</el-button>
        </span>
    </el-dialog>
</template>
<style lang="scss" scoped>
.cropper_elem{
    width: 625px;
    height: 375px;
    margin: 0 auto;
}
</style>

<script>

import { VueCropper }  from 'vue-cropper' 
// 文档地址：https://github.com/xyxiao001/vue-cropper
// import api from '../util/api';
// let root = process.env.API_ROOT;
/*
    图片裁剪插件：
    参数说明：
    cropperData：Object
    内imgUrl为blob格式图片链接，labe：相应表单要截取的图片的字段名 ;fixedNumber: [1,1]图片比例(选填)默认为5:3  -----截图成功后通过bindCropper接收数据
*/
export default {
    props:['cropperData'],
    components: {VueCropper},
    mounted() {
    },
    data(){
        return {
            cropperDialogVisible: false,
            isCropperLoading: false,
            cropperOption: {
                img: '',
                outputSize: 1,
                outputType: 'png',
                autoCrop: true,
                fixed: true,
                fixedNumber: [5,3],
                fixedBox: true,
                maxImgSize: 6000,
                mode: '80% 80%',
                info: false,
                centerBox: false,
            },
        };
    },
    methods: {   
        switchCropperDialog(type) {//type: 1: 关闭 不传为打开
            if(type) {
                this.cropperDialogVisible = false;
                this.$emit('bindCropper',{type: 0,label: this.cropperData.label,data: ''})
            } else {
                this.cropperDialogVisible = true;
            }

        },
        bindChangeScale(type) {
            this.$refs.cropper.changeScale(type) 
        },
        cropperHandler() {
            if(this.isCropperLoading) return false;
            this.isCropperLoading = true;
            this.$refs.cropper.startCrop();
            // 获取截图的blob数据
            this.$refs.cropper.getCropBlob((data) => {
                let files = new window.File([data], new Date().getTime(), {type: data.type})
                this.$base.aliupload(files,'image',(res) => {
                    this.$emit('bindCropper',{type: 1,label: this.cropperData.label,data: res})
                    this.isCropperLoading = false;
                    this.switchCropperDialog(1);
                });
            })
        },
    },
    destroyed() {
       
    }
}
</script>
