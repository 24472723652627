<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item><img class="page_breadcrumb_icon" src="/static/images/portal/home.png" alt="首页"><span
          class="page_breadcrumb_text">首页</span></el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">

      <div class="page-content-item digitalDesItem xlh-temp" v-if="showSaas">
        <div class="leftBox">
          <p class="digitalDesc" style="margin-bottom: 10px">小陆汇SaaS系统</p>
          <p class="digitalDesc content_part">轻松三步搭建专属你自己的微站/小程序 <br/>
            小陆汇可以让你的数据私有化，拥有独立的小程序店铺/微店，更丰富的营销工具、渠道分销、推广员主页，还可以对用户数据进行追踪，实现精准营销
          </p>
          <div class="selectDiv">
            <div class="actived" @click="goSaas(1)">申请试用</div>
            <div @click="goSaas(2)" class="last">立即注册体验</div>
          </div>
        </div>
        <div class="rightImg">
          <img src="https://lxh-uni-file.oss-cn-shanghai.aliyuncs.com/index/2023_portal_saas.png">
        </div>
        <div class="portal_close" @click="closeSass">
          <img src="https://lxh-uni-file.oss-cn-shanghai.aliyuncs.com/index/close.png">
        </div>
      </div>
      <div class="page-content-item">
        <div class="index_module">
          <div class="index_title">数据概况</div>
          <div class="index_item">
            <div @click="bind2Page('/portal/census/order?orderRead=1')" class="index_data_item">
              <div class="index_data_name"><span class="index_data_name_text">总报名人数<el-tooltip class="item"
                                                                                                    effect="dark"
                                                                                                    content="发布在陆想汇平台所有活动、课程的报名人数总量"
                                                                                                    placement="top"><i
                  class="el-icon-question"></i></el-tooltip></span></div>
              <div class="index_data_num">
                <span>{{ indexData.orderCount | viewNumFormat }}</span>
                <div class="index_data_num_right" v-if="indexData.addApplyCount">
                  <img class="num_up_img" src="/static/images/portal/index_up.png"/>
                  <span class="num_up_text">{{ indexData.addApplyCount | viewNumFormat }}</span>
                </div>
              </div>
            </div>
            <div @click="bind2Page('/portal/census/view')" class="index_data_item">
              <div class="index_data_name"><span class="index_data_name_text">总浏览量<el-tooltip class="item"
                                                                                                  effect="dark"
                                                                                                  content="发布在陆想汇平台所有活动、课程的总浏览次数"
                                                                                                  placement="top"><i
                  class="el-icon-question"></i></el-tooltip></span></div>
              <div class="index_data_num">
                <span>{{ indexData.sumVisitsCount | viewNumFormat }}</span>
                <div class="index_data_num_right" v-if="indexData.addVisitsCount">
                  <img class="num_up_img" src="/static/images/portal/index_up.png"/>
                  <span class="num_up_text">{{ indexData.addVisitsCount | viewNumFormat }}</span>
                </div>
              </div>
            </div>
            <div @click="bind2Page('/portal/census/order?orderRead=2')" class="index_data_item">
              <div class="index_data_name"><span class="index_data_name_text">订单总金额<el-tooltip class="item"
                                                                                                    effect="dark"
                                                                                                    content="发布在陆想汇平台所有活动、课程收入总金额（历史总收入金额，包含已结算）"
                                                                                                    placement="top"><i
                  class="el-icon-question"></i></el-tooltip></span></div>
              <div class="index_data_num">
                <span
                    style="font-size: 20px;">￥</span><span>{{ indexData.sumSuccessOrderAmount | viewNumFormat }}</span>
                <div class="index_data_num_right" v-if="indexData.addChargeCount">
                  <img class="num_up_img" src="/static/images/portal/index_up.png"/>
                  <span class="num_up_text">￥{{ indexData.addChargeCount | viewNumFormat }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="page-content-item digitalDesItem" style="padding: 40px 0 40px 40px;"
           v-if="indexData.accountId == 1463 || indexData.accountId == 1">
        <div style="width: calc(100% - 362px)">
          <p class="digitalDesc">
            数字会场来啦！发布活动，体验不一样的直播间，沉浸式会场，更有更多社交玩法等你探索，公开发布获取平台流量，私密发布收集专属您的私域流量。</p>
          <div class="selectDiv">
            <div @click="goDigitalVenueActivity(0)">发布私有活动</div>
            <div @click="goDigitalVenueActivity(1)" class="last">发布公开活动</div>
          </div>
        </div>
        <div class="rightImg">
          <img src="https://lxh-uni-file.oss-cn-shanghai.aliyuncs.com/2023_portal_index.png"></img>
        </div>
      </div>
      <div class="page-content-item">
        <div class="index_module">
          <div class="index_title">发布</div>
          <div class="index_item">
            <div @click="editDialog = true" class="index_release_item">
              <img class="index_release_img" src="/static/images/portal/index/release_04.png"/>
              <div class="index_release_name">发布活动</div>
            </div>
            <div @click="checkRbac('002')" class="index_release_item">
              <img class="index_release_img" src="/static/images/portal/index/release_05.png"/>
              <div class="index_release_name">发起直播</div>
            </div>
            <div @click="checkRbac('0029')" class="index_release_item">
              <img class="index_release_img" src="/static/images/portal/index/release_06.png"/>
              <div class="index_release_name">发布课程</div>
            </div>
            <div @click="checkRbac('005')" class="index_release_item">
              <img class="index_release_img" src="/static/images/portal/index/release_07.png"/>
              <div class="index_release_name">发布文章</div>
            </div>
            <div @click="checkRbac('004')" class="index_release_item">
              <img class="index_release_img" src="/static/images/portal/index/release_08.png"/>
              <div class="index_release_name">发布资源</div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-content-item">
        <div class="index_module">
          <div class="index_title">待办事项</div>
          <div class="index_item">
            <div @click="bind2Page('/portal/activity/order?type=1')" class="index_todo_item">
              <img class="index_todo_img" src="/static/images/portal/index/todo_01.png">
              <div class="index_todo_title">待审核</div>
              <div class="index_todo_num"><span>{{ indexData.pendingOrderCount || '-' }}</span><span
                  v-if="indexData.pendingOrderCount" class="red_dot"></span></div>
            </div>
            <div @click="bind2Page('/portal/activity/interact')" class="index_todo_item">
              <img class="index_todo_img" src="/static/images/portal/index/todo_02.png">
              <div class="index_todo_title">互动消息</div>
              <div class="index_todo_num"><span>{{ indexData.interactionCount || '-' }}</span><span
                  v-if="indexData.interactionCount" class="red_dot"></span></div>
            </div>
            <div @click="bind2Page('/portal/activity/interact?type=1')" class="index_todo_item">
              <img class="index_todo_img" src="/static/images/portal/index/todo_03.png">
              <div class="index_todo_title">用户提问</div>
              <div class="index_todo_num"><span>{{ indexData.answersCount || '-' }}</span><span
                  v-if="indexData.answersCount" class="red_dot"></span></div>
            </div>
          </div>
        </div>
      </div>
      <div class="page-content-item">
        <div class="index_module">
          <div class="index_title">平台服务</div>
          <div class="index_item">
            <div @click="bindSwicthService(0)" class="index_service_item">
              <img class="service_img" src="/static/images/portal/login/reg_service_01.png"/>
              <div class="service_name">营销推广</div>
            </div>
            <div @click="bindSwicthService(1)" class="index_service_item">
              <img class="service_img" src="/static/images/portal/login/reg_service_02.png"/>
              <div class="service_name">嘉宾邀约</div>
            </div>
            <div @click="bindSwicthService(2)" class="index_service_item">
              <img class="service_img" src="/static/images/portal/login/reg_service_03.png"/>
              <div class="service_name">会务服务</div>
            </div>
            <div @click="bindSwicthService(3)" class="index_service_item">
              <img class="service_img" src="/static/images/portal/login/reg_service_04.png"/>
              <div class="service_name">技术支持</div>
            </div>
          </div>
          <div class="index_service_line"><span id="activeLine" class="line_active"></span></div>
          <div v-show="selectService == 0" class="index_service_content">
            <div class="service_content_left">
              <div class="service_content_left_title">营销推广平台服务</div>
              <div class="service_content_left_desc">
                平台拥有100万注册用户，超过5000个微信群，用户标签化，活动信息精准推广到目标用户群体，可以通过大数据技术对金融业务的营销宣传提供服务支持。<br/><br/>可提供整体会议站内推广营销方案。
              </div>
              <div @click="bindGoPage('/portal/service/list')" class="service_content_left_btn">立即购买</div>
            </div>
            <img class="service_content_right" src="/static/images/portal/index/service_01.png"/>
          </div>
          <div v-show="selectService == 1" class="index_service_content">
            <div class="service_content_left">
              <div class="service_content_left_title">嘉宾邀约服务</div>
              <div class="service_content_left_desc">
                陆想汇拥有5000多名相关领域专家资源，覆盖宏观经济、国际国内政治政策、大类资产配置、投资策略、行业研究、金融科技、创新创业等多个领域，直接与嘉宾沟通邀约，也可做企业层面嘉宾咨询。
              </div>
              <div @click="bindGoPage('/portal/account/invite')" class="service_content_left_btn">立即邀约</div>
            </div>
            <img class="service_content_right" src="/static/images/portal/index/service_04.png"/>
          </div>
          <div v-show="selectService == 2" class="index_service_content">
            <div class="service_content_left">
              <div class="service_content_left_title">会务服务</div>
              <div class="service_content_left_desc">
                公司可提供从会议主题策划、嘉宾邀约接待、主持、报名签到、流程管理、现场搭建、摄影摄像到线上宣传报道、直播互动等全流程会务服务，参与策划实施大量国内知名大型论坛。
              </div>
              <div @click="contactDialog = true;contactBtnShow=true" class="service_content_left_btn">立即咨询</div>
            </div>
            <img class="service_content_right" src="/static/images/portal/index/service_02.png"/>
          </div>
          <div v-show="selectService == 3" class="index_service_content">
            <div class="service_content_left">
              <div class="service_content_left_title">技术支持服务</div>
              <div class="service_content_left_desc">
                公司技术团队的核心成员都来自于中国知名的互联网公司，在电子商务、金融科技等领域有着丰富的项目经验，公司自成立以来，致力于为金融机构提供信息技术服务，包括会议营销、签到系统、直播平台技术支持。已为多家金融机构、创业园区、金融媒体等提供软件开发、技术咨询服务。<br/><br/>公司以互联网+FinTech为发展战略。公司的核心业务包括软件产品研发，行业应用解决方案和互联网技术咨询
              </div>
              <div @click="contactDialog = true;contactBtnShow=false" class="service_content_left_btn">立即咨询</div>
            </div>
            <img class="service_content_right" src="/static/images/portal/index/service_03.png"/>
          </div>
        </div>
      </div>
    </div>
    <!-- 联系弹窗 -->
    <el-dialog
        title="联系客服"
        :visible.sync="contactDialog"
        width="500px">
      <div class="portal_header_contact">
        <img class="portal_header_contact_img" src="/static/images/portal/wx_contact_code.png" alt="联系我们">
        <div class="portal_header_contact_tips">↑ 扫码添加客服微信</div>
        <div class="portal_header_contact_bot" :class="contactBtnShow?'':'contact_btn_show'">客服电话：17301836228</div>
        <div v-if="contactBtnShow" class="portal_header_contact_handler">
          <el-button @click.stop="bind2Page('/portal/service/func')" style="width: 140px;" type="primary">直播下单
          </el-button>
          <el-button style="margin-left: 50px;width: 140px;" @click.stop="bind2Page('/portal/account/service')"
                     type="primary">填写需求
          </el-button>
        </div>
      </div>
    </el-dialog>
    <!-- 联系弹窗 end -->
    <!-- 发布活动弹窗 -->
    <el-dialog
        title="发布活动"
        :visible.sync="editDialog"
        width="700px">
      <div style="padding-bottom: 20px;">
        <div style="font-size:16px;color:#999999;margin-bottom:30px;text-align: center;">请选择要发布的活动类型</div>
        <div style="text-align:left;">
          <el-button plain @click.stop="checkRbac('001')"
                     style="width: 140px;letter-spacing: 2px;margin-left: 10px;margin-bottom: 15px;" type="primary">线下活动
          </el-button>
          <el-button plain @click.stop="checkRbac('002')" style="width: 140px;letter-spacing: 2px;" type="primary">
            线上活动
          </el-button>
          <el-button plain @click.stop="checkRbac('003')" style="width: 140px;letter-spacing: 2px;" type="primary">
            收费活动
          </el-button>
          <el-tooltip class="item" effect="dark" content="*199元/场，在陆想汇平台报名，跳转至指定链接参会"
                      placement="top-start">
            <el-button class="out_link_after" plain @click.stop="checkRbac('0032')"
                       style="width: 140px;letter-spacing: 2px;" type="primary">外部活动
            </el-button>
          </el-tooltip>
        </div>

      </div>
    </el-dialog>
    <!-- 发布活动弹窗 end -->
    <!-- 购买单项功能弹窗组件 -->
    <sysRbac @bindComplete="bindFuncComplete" ref="pubRbac"></sysRbac>
    <!-- 申请试用 -->
    <el-dialog
        title="申请试用"
        :visible.sync="applyDialog"
        width="500px">
      <div class="portal_header_contact">
        <el-form @submit.native.prevent
                 :model="projectQueryForm"
                 :rules="formRule"
                 ref="projectQueryForm"
                 class="list_search_form"
                 id="projectQueryForm"
                 style="padding-left: 10px;padding-top:10px;">
          <el-form-item prop="name" label="姓名">
            <el-input v-model="projectQueryForm.name" placeholder="您的姓名"></el-input>
          </el-form-item>
          <el-form-item prop="userMobile" label="手机号">
            <el-input v-model="projectQueryForm.userMobile" placeholder="手机号码"></el-input>
          </el-form-item>
          <el-form-item prop="companyName" label="公司名称">
            <el-input v-model="projectQueryForm.companyName" placeholder="公司名称"></el-input>
          </el-form-item>


          <el-form-item style="margin-top: 40px;">
            <el-button :loading="saveFlag" @click="submitForm" class="submit_btn" style="width: 100%" type="primary">
              提交
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 申请试用 end -->
  </div>
</template>
<style lang="scss" scoped>
@import '../style/portal/index.scss';

.out_link_after {
  position: relative;

  &::after {
    // content: '*199元/场，在陆想汇平台报名，跳转至指定链接参会';
    position: absolute;
    left: 0;
    bottom: -26px;
    height: 26px;
    line-height: 26px;
    font-size: 10px;
    color: #999999;
    letter-spacing: normal;
  }
}

.portal_header_contact_img {
  display: block;
  height: 160px;
  width: 160px;
  margin: 10px auto;
}

.portal_header_contact_tips {
  text-align: center;
  font-size: 16px;
  color: #333333;
  line-height: 20px;
}

.portal_header_contact_bot {
  font-size: 16px;
  color: #333333;
  font-weight: 500;
  text-align: center;
  line-height: 20px;
  margin: 0 auto 20px;
  padding: 20px 0;
  border-bottom: 1px solid #f2f2f2;
  border-top: none;

  &.contact_btn_show {
    border-bottom: none;
    margin-bottom: 0;
  }
}

.portal_header_contact_handler {
  padding-top: 10px;
  text-align: center;
}
</style>

<script>
import api from '@/util/api'

export default {
  created () {
    this.showSaas = true
    this.pageInit()
  },
  activated () {
    this.pageInit()
  },
  data () {
    return {
      indexData: {
        addApplyCount: 0,
        addChargeCount: 0,
        addVisitsCount: 0,
        answersCount: 0,
        interactionCount: 0,
        orderCount: 0,
        pendingOrderCount: 0,
        sumSuccessOrderAmount: 0,
        sumVisitsCount: 0,

      },
      checkSumFlag: false,//发布直播
      selectService: -1,
      contactDialog: false,
      editDialog: false,
      contactBtnShow: true,
      applyDialog: false,
      projectQueryForm: {
        name: '',
        userMobile: '',
        companyName: ''
      },
      formRule: {
        userMobile: [
          { required: true, message: '请输入手机号', trigger: 'change' },
          {
            validator: (rule, value, callback) => {
              if (!this.$isPhone(value)) {
                callback(new Error('请输入正确的手机号码'))
              } else {
                callback()
              }
            }, trigger: ['blur']
          }
        ],
        companyName: { required: true, message: '请输入公司名称', trigger: ['change'] }
      },
      saveFlag: false,
      showSaas: true
    }
  },
  filters: {
    viewNumFormat: function (val) {
      if (typeof (val) == 'undefined' || typeof (val) == 'null') {
        return '0'
      } else {
        if (isNaN(val)) {
          return '0'
        } else {
          if (val < 100000) {
            return val
          } else {
            return parseFloat(val / 10000).toFixed(2) + 'w'
          }
        }
      }
    }
  },
  methods: {
    pageInit () {
      this.getHomeData()
      this.$nextTick(() => {
        this.bindSwicthService(0)
      })
      window.onresize = () => {
        this.bindSwicthService(this.selectService)
      }
    },
    bindFuncComplete () {//购买单项功能支付成功回调
      // console.log('支付完成：',res)
      this.$message.success('支付成功')
      this.pageInit()
    },
    checkRbac (code) {//检查功能权限：
      this.$cont.check(code).then(() => {
        switch (code) {
          case '001':
            this.goEditActivity(0, 1)
            break
          case '002':
            this.goEditActivity(1)
            break
          case '003':
            this.goEditActivity(0, 2)
            break
          case '004':
            this.goEditResource()
            break
          case '005':
            this.goEditArticle()
            break
          case '0029':
            this.goEditCourse()
            break
          case '0032':
            this.$router.push({
              path: '/portal/activity/edit',
              query: {
                isPubOut: 1
              }
            })
            break
          default:
            break
        }
      }).catch((res) => {
        if (res.status == -1) {
          this.$message.error(res.msg)
          return false
        }
        this.$confirm(res.msg, '提示', {
          type: 'warning'
        }).then(() => {
          this.$nextTick(() => {
            this.$refs.pubRbac.switchRbacDialog(code)
          })
        })

      })
    },
    bindSwicthService (index) {
      // if(this.selectService == index) return false;
      this.selectService = index
      try {
        document.getElementById('activeLine').style.left = (document.getElementsByClassName('index_service_item')[index].offsetLeft + 6) + 'px'
      } catch (error) {
        console.error(error)
      }
    },
    bindGoPage (url) {
      this.$router.push(url)
    },
    getHomeData () {
      this.$api.post({
        url: 'api-activity/partnersIndex',
        success: res => {
          console.log(res)
          this.indexData = res
        },
        fail: res => {
          console.log(res)
        }
      })
    },
    beforeLeave (path) {
      switch (path) {
        case '/portal/census/order?orderRead=1':
          this.$api.post({ url: 'api-activity/updateOrderRead', data: { type: 0 } })
          break
        case '/portal/census/view':
          this.$api.post({ url: 'api-activity/updateVisitsRead' })
          break
        case '/portal/census/order?orderRead=2':
          this.$api.post({ url: 'api-activity/updateOrderRead', data: { type: 1 } })
          break
        case '/portal/activity/order?type=1':
          this.$api.post({ url: 'api-activity/updateOrderRead', data: { type: 2 } })
          break
        case '/portal/activity/interact':
          this.$api.post({ url: 'api-activity/updateCommentRead', data: { type: 0 } })
          break
        case '/portal/activity/interact?type=1':
          this.$api.post({ url: 'api-activity/updateCommentRead', data: { type: 1 } })
          break
        default:
          break
      }
    },
    bind2Page (path) {//页面跳转
      this.beforeLeave(path)
      if (path == '/portal/census/order?orderRead=1') {
        this.$router.push({
          path: path,
          query: {
            newOrder: this.indexData.addApplyCount
          }
        })
      } else if (path == '/portal/census/order?orderRead=2') {
        this.$router.push({
          path: path,
          query: {
            newAmount: this.indexData.addChargeCount
          }
        })
      } else {
        this.$router.push({
          path: path
        })
      }

    },
    goDigitalVenueActivity (isOpen) {//发布数字会场活动
      this.$router.push({
        path: '/portal/activity/edit?isOpen=' + isOpen + '&isDigitalVenue=1&isLive=1'
      })
    },
    visitsHistory (url) {
      api.post({
        url: 'api-portal/visitsHistory',
        data: { url: url }
      })
    },
    goSaas (type) {
      if (type === 1) {
        this.projectQueryForm = {}
        this.applyDialog = true
        this.visitsHistory(location.href)
        return
      }
      window.open('https://lu.link/portal/login')
      this.visitsHistory('https://lu.link/portal/login')
    },
    closeSass () {
      this.showSaas = false
    },
    submitForm () {
      this.saveFlag = true
      this.$refs['projectQueryForm'].validate((valid) => {
        if (valid) {//通过
          this.$api.post({
            url: 'api-user/savePartnerTrialRecord',
            data: {
              ...this.projectQueryForm,
              source: 1
            },
            success: () => {
              this.applyDialog = false
              this.saveFlag = false
              this.$message.success('提交成功！工作人员将在3个工作日内联系你，为你安排试用。')
            },
            fail: res => {
              this.applyDialog = false
              this.saveFlag = false
              this.$message.error(res.data.cnMsg)
            },
            complete: () => {
              this.saveFlag = false
            }
          })
        } else {//未通过
          this.$message.warning('请完整填写表单')
          this.saveFlag = false
        }
      })
    },
    goEditCourse () {//发布课程
      this.$router.push({
        path: '/portal/course/edit'
      })
    },
    goEditArticle () {//发布文章
      this.$router.push({
        path: '/portal/article/edit'
      })
    },
    goEditResource () {
      this.$router.push({
        path: '/portal/resource/edit'
      })
    },
    goEditActivity (type, isFree) {//发布活动
      if (type) {
        if (this.checkSumFlag) return false
        this.checkSumFlag = true
        this.$api.post({
          url: 'api-activity/getPartnersSumDataUsage',
          success: res => {
            // console.log(res);
            this.checkSumFlag = false
            if (res && res > 0) {
              this.$router.push({
                path: '/portal/activity/edit',
                query: {
                  isLive: 1,
                  isPubFree: 1
                }
              })
            } else {
              this.$confirm('当前账户流量不足，是否立即去充值?', '提示', {
                confirmButtonText: '确定',
                type: 'warning'
              }).then(() => {
                this.$router.push('/portal/live/flow')
              }).catch(() => {})
            }
          },
          fail: res => {
            this.checkSumFlag = false
            this.$message(res.data.cnMsg)
          }
        })
      } else {
        if (isFree == 1) {
          this.$router.push({
            path: '/portal/activity/edit',
            query: {
              isLive: 2,
              isPubFree: isFree
            }
          })
        } else {
          this.$router.push({
            path: '/portal/activity/edit',
            query: {
              isPubFree: isFree
            }
          })
        }

      }

    }
  }
}
</script>

