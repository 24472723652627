export default {
    path: 'article',
    meta: {
        title: '文章管理'
    },
    component: r => require.ensure([], () => r(require('../../portalPage/article/main')), 'portalArticle'),
    children: [
        {
            path: '',
            redirect: 'list'            
        },
        {
            name: 'portalArticleList',
            path: 'list',
            meta:{
                title:'文章列表'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/article/list')), 'portalArticleList')
        },
        {
            name: 'portalArticleEdit',
            path: 'edit',
            meta:{
                title:'文章编辑'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/article/edit')), 'portalArticleEdit')
        }
        
        
    ]
}