import {Message} from 'element-ui';
import router from '../router';
// 配置API接口地址
var root = process.env.API_ROOT
// 引用axios
var axios = require('axios')
var qs = require('qs')
axios.defaults.withCredentials = true
// 自定义判断元素类型JS
function toType (obj) {
    return ({}).toString.call(obj).match(/\s([a-zA-Z]+)/)[1].toLowerCase()
}
// 参数过滤函数
function filterNull (o) {
    o = o || {};
    for (var key in o) {
        if (o[key] === null) {
            delete o[key]
        }
        if (toType(o[key]) === 'string') {
            o[key] = o[key].trim()
        } else if (toType(o[key]) === 'object') {
            o[key] = filterNull(o[key])
        } else if (toType(o[key]) === 'array') {
            o[key] = filterNull(o[key])
        }
    }
    return o;
}
/*
  接口处理函数
  这个函数每个项目都是不一样的，我现在调整的是适用于
  https://cnodejs.org/api/v1 的接口，如果是其他接口
  需要根据接口的参数进行调整。参考说明文档地址：
  https://cnodejs.org/topic/5378720ed6e2d16149fa16bd
  主要是，不同的接口的成功标识和失败提示是不一致的。
  另外，不同的项目的处理方法也是不一致的，这里出错就是简单的alert
*/

function apiAxios (method, obj) {
    if (obj.data) {
        obj.data = filterNull(obj.data)
    }
    let header = obj.header || {};
    header['Access-Control-Allow-Origin'] = '*';
    header['Access-Control-Allow-Headers'] = 'token,request_source';
    header['Access-Control-Allow-Methods'] = 'POST, PUT, UPLOAD, GET, OPTIONS';
    header['Access-Control-Max-Age'] = 86400;
	header['portalToken'] = localStorage.getItem('portalToken') || '';
	header['request_source'] =  12;
    header['device_id'] = localStorage.getItem('device_id') || '';
	header['content-type'] = obj.contentType || 'application/x-www-form-urlencoded';//application/json;charset=UTF-8
    let axiosData;
    root = "/";
    if(method==='UPLOAD'){
        axiosData = {
            method: 'POST',
            url: obj.url,
            data: obj.data,
            baseURL: root,
            withCredentials: true,
            headers: header,
            actionType: 'ajax'
        };
    }else{
        axiosData = {
            method: method,
            url: obj.url,
            data: method === 'POST' || method === 'PUT' ? qs.stringify(obj.data) : null,
            params: method === 'GET' || method === 'DELETE' ? obj.data : null,
            baseURL: root,
            withCredentials: true,
            headers: header,
            actionType: 'ajax'
        };
 
    }
    axios(axiosData).then(function (res) {
        if (res.status === 200) {
            if(res.data.status === 200) {
                if(obj.success){
                    obj.success(res.data.data)
                }
            } else if(res.data.status == 400003 || res.data.status == 400004){
                localStorage.removeItem('portalToken');
                let goUrl  = '';
                if(location.href.indexOf('goUrl=') != -1) {
                    goUrl = location.href.split('goUrl=')[1]
                } else {
                    if(res.data.status == 400003){
                        Message.warning('登录状态已过期，请重新登录');
                    }
                    goUrl = encodeURIComponent(location.href);
                }
                router.push({
                    path: '/portal/login',
                    query: {
                        goUrl: goUrl
                    }
                })
                // location.pathname='/portal/login?goUrl=' + encodeURIComponent(location.href);
                return;
            } else if (res.data.status == 410042) {
                router.push({
                    path: '/portal/account/info',
                })
            } else {
                if(obj.fail){
                    obj.fail(res);
                }else{
                    var errorMsg = res.data.cnMsg;
                    Message.error(errorMsg)
                }
            }
            
        } else {
            if(obj.fail){
                obj.fail(res);
            } else {
                if(res.data.cnMsg) Message.error(res.data.cnMsg)
            }
        } 
        if(obj.complete){
            obj.complete(res);
        }
    })
    .catch(function (err) {
        console.log(err)
        let res = err.response;
        if(obj.complete){
            obj.complete();
        }
        if (err) {
            // Message.error('系统繁忙请稍后再试')
            return
        }
    })
}

// 返回在vue模板中的调用接口
export default {
    get: function (obj) {
        return apiAxios('GET', obj)
    },
    post: function (obj) {
        return apiAxios('POST', obj)
    },
    put: function (obj) {
        return apiAxios('PUT', obj)
    },
    delete: function (obj) {
        return apiAxios('DELETE', obj)
    },
    upload: function(obj){
        return apiAxios('UPLOAD', obj);
    }
}
