<script type="text/javascript">
    import api from '../util/api';
    var root = process.env.API_ROOT
    Date.prototype.format = function(format){ 
        var o = { 
            "M+" : this.getMonth()+1, //month 
            "d+" : this.getDate(), //day 
            "h+" : this.getHours(), //hour 
            "m+" : this.getMinutes(), //minute 
            "s+" : this.getSeconds(), //second 
            "q+" : Math.floor((this.getMonth()+3)/3), //quarter 
            "S" : this.getMilliseconds() //millisecond 
        } 
        
        if(/(y+)/.test(format)) { 
            format = format.replace(RegExp.$1, (this.getFullYear()+"").substr(4 - RegExp.$1.length)); 
        } 
        
        for(var k in o) { 
            if(new RegExp("("+ k +")").test(format)) { 
                format = format.replace(RegExp.$1, RegExp.$1.length==1 ? o[k] : ("00"+ o[k]).substr((""+ o[k]).length)); 
            } 
        } 
        return format; 
    }
    export default {
        deepClone(obj) {
            // 先检测是不是数组和Object
            let isArr = Array.isArray(obj);
            let isJson = Object.prototype.toString.call(obj) === '[object Object]';
            if (isArr) {
                // 克隆数组
                let newObj = [];
                for (let i = 0; i < obj.length; i++) {
                newObj[i] = this.deepClone(obj[i]);
                }
                return newObj;
            } else if (isJson) {
                // 克隆Object
                let newObj = {};
                for (let i in obj) {
                newObj[i] = this.deepClone(obj[i]);
                }
                return newObj;
            }
            // 不是引用类型直接返回
            return obj;
        },
        jsonToFormSerialize(jsonObj) {
            let str = '';
            for (var key in jsonObj) {
                str += key + '=' + jsonObj[key] + '&';
            }
            str = str.substring(0, str.length - 1);
            return str;
        },
        getuuid(len, radix) {
            var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
            var uuid = [], i;
            radix = radix || chars.length;
        
            if (len) {
            // Compact form
            for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random()*radix];
            } else {
            // rfc4122, version 4 form
            var r;
        
            // rfc4122 requires these characters
            uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
            uuid[14] = '4';
        
            // Fill in random data.  At i==19 set the high bits of clock sequence as
            // per rfc4122, sec. 4.1.5
            for (i = 0; i < 36; i++) {
                if (!uuid[i]) {
                r = 0 | Math.random()*16;
                uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
                }
            }
            }
        
            return uuid.join('');
        },
        aliupload(file,fileType,callback,errorBack) {
            if (!file) {
                alert("文件不能为空");
                return;
            }

            var extension = "";
            let myFileType = fileType;
            if (fileType == "image") {
                extension = "jpg";
            }
        
            if (fileType == "file") {
                var fileName  = file.name;
                 extension = fileName.substring(fileName.lastIndexOf('.') + 1);
                  if (fileType == "file" && extension.toUpperCase() != "DOC" && extension.toUpperCase() != "DOCX" && extension.toUpperCase() != "PDF" &&
                        extension.toUpperCase() != "PPT" && extension.toUpperCase() != "PPTX") {
                        alert("请上传 doc, docx, pdf, ppt, pptx文件");
                        return;
                    }
            }
           
            if(fileType == "video") {
                var fileName  = file.name;
                extension = fileName.substring(fileName.lastIndexOf('.') + 1);
                myFileType = "file";
                if(file.size > 1024 * 1024 * 1024 * 2) {
                    alert("文件过大，请上传小于2GB的文件");
                    return;
                }
            } else {
                if (file.size > 1024 * 1024 * 100) {
                    alert("文件过大，请上传小于100M的文件");
                    return;
                }
            }
            
            api.post({
                url: 'api-activity/getSts',
                data: {
                   fileType: myFileType 
                },
                success: res => {
                    // console.log('签名信息：',res);
                    var storeAs = res.UUID + "." + extension;
                    var client = new OSS.Wrapper({
                        accessKeyId: res.AccessKeyId,
                        accessKeySecret: res.AccessKeySecret,
                        stsToken: res.SecurityToken,
                        endpoint: res.Protocol + "://" + res.EndPoint,
                        secure: true,
                        bucket: res.BucketName
                    });
                    client.multipartUpload(storeAs, file).then(function (result) {
                        if(callback){
                            callback(res.Protocol + "://" + res.BucketName + '.' + res.EndPoint + '/' + result.name);
                        }
                    }).catch(function (err) {
                        console.log(err)
                        alert('上传失败，请稍后重试')
                    });
                },
                fail: res => {
                    if(errorBack){
                        errorBack(res)
                    } else {
                        return alert(res.data.cnMsg);
                    }
                }
            })
            
        },
        fileUpload(fileUrl,fileName,callback){
            let fileObj = fileUrl;
            let formData = new FormData();
            formData.append(fileName, fileObj);
            api.upload({
                url: 'api-portal/importUser',
                contentType: 'multipart/form-data',
                data: formData,
                success: res => {
                    // this.$message.success('上传成功');                    
                    callback(res);
                }
            })
            
            // api.post({
            //     url: root + "/file/saveInfo.do",
            //     data: {
            //         fileName:fileName,
            //         fileUrl:fileUrl
            //     },
            //     success: res => {
            //         callback(res);
            //     }
            // });
        },
        isUrl(str) {//网址验证
            if(str) {
                let strRegex = "^((https|http|ftp|rtsp|mms)?://)"
                + "?(([0-9a-z_!~*'().&=+$%-]+: )?[0-9a-z_!~*'().&=+$%-]+@)?" //ftp的user@
                + "(([0-9]{1,3}\.){3}[0-9]{1,3}" // IP形式的URL- 199.194.52.184
                + "|" // 允许IP和DOMAIN（域名）
                + "([0-9a-z_!~*'()-]+\.)*" // 域名- www.
                + "([0-9a-z][0-9a-z-]{0,61})?[0-9a-z]\." // 二级域名
                + "[a-z]{2,6})" // first level domain- .com or .museum
                + "(:[0-9]{1,4})?" // 端口- :80
                + "((/?)|" // a slash isn't required if there is no file name
                + "(/[0-9a-z_!~*'().;?:@&=+$,%#-]+)+/?)$";
                let re = new RegExp(strRegex);
                return re.test(str)
            } else {
                return false;
            }
        },
        clearNoNum(e) {
            if(e){
                e = e.replace(/[^\d.]/g, "");
                e = e.replace(/^\./g, "");
                e = e.replace(/\.{2,}/g, ".");
                e = e.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
                e = e.replace(/^0[\d]+/g, "0");
                e = e.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
            }
            return e;
        },
        priceFormat(num) {
            if (typeof(num) == 'undefined') {
                return '0.00'
            } else {
                return parseFloat(num).toFixed(2).replace(/\d(?=(?:\d{3})+\b)/g, `$&,`)
            }

        },
        validModulePrivilege(module){
            var userType = window.localStorage.getItem('userType');
            var websiteModule = JSON.parse(window.localStorage.getItem('websiteModule'));
            if(userType == 1){
                var websiteInfo = JSON.parse(window.localStorage.getItem('websiteInfo'));
                if(websiteInfo.isTryOut == 1){
                    return true;
                }else{
                    if(websiteInfo.auditStatus != 2){
                        return false;
                    }else{
                        if(websiteModule[module] == 1){
                            return true;
                        }else{
                            return false;
                        }
                    }
                }
            }else{
                if(websiteModule[module] == 1){
                    return true;
                }else{
                    return false;
                }
            }
        },
        isLogin(callback){
              api.post({
                url: "api/authentication-service/user/isLogin",
                data: {
                    token: localStorage.getItem('portalToken')
                },
                success: res => {
                     callback(res);
                }
            });
        }
    }
</script>