export default {
    path: 'live',
    meta: {
        title: '直播管理'
    },
    component: r => require.ensure([], () => r(require('../../portalPage/live/main')), 'portalLive'),
    children: [
        {
            path: '',
            redirect: 'list'
        },
        {
            name: 'portalLiveList',
            path: 'list',
            meta:{
                title:'直播列表'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/live/list')), 'portalLiveList')
        },
        {
            name: 'portalLiveStream',
            path: 'stream',
            meta:{
                title:'推流管理'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/live/stream')), 'portalLiveStream')
        },
        {
            name: 'portalLiveRoom',
            path: 'room',
            meta:{
                title:'直播间管理'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/live/room')), 'portalLiveRoom')
        },
        {
            name: 'portalLiveGoods',
            path: 'goods',
            meta:{
                title:'直播带货'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/live/goods')), 'portalLiveGoods')
        },
        {
            name: 'portalLiveTuwen',
            path: 'tuwen',
            meta:{
                title:'图文直播'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/live/tuwen')), 'portalLiveTuwen')
        },
        {
            name: 'portalLivePlayback',
            path: 'playback',
            meta:{
                title:'回放设置'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/live/playback')), 'portalLivePlayback')
        },
        {
            name: 'portalLiveFlow',
            path: 'flow',
            meta:{
                title:'流量管理'
            },
            component: r => require.ensure([], () => r(require('../../portalPage/live/flow')), 'portalLiveFlow')
        }
        
    ]
}